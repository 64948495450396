import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["modal", "content"]
  
  toggleModal() {
    this.modalTarget.classList.toggle("hidden")
  }

  closeModal(e) {
    if (e.target.closest(".modal-article")) return;
    this.modalTarget.classList.add("hidden")
  }

}