import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ["input", "counter"];

  connect() {
    console.log("conn");
  }


  countSelectedFiles() {
    // console.log("count");
    console.log(this.inputTarget.files.length);
    var selectedFilesCount = this.inputTarget.files.length;
    if (selectedFilesCount > 0) {
      this.counterTarget.classList.remove("hidden");
      this.counterTarget.textContent = selectedFilesCount;
    } else {
      this.counterTarget.classList.add("hidden");
    }
  }



}