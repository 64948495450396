import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['form'];

  connect() {
    console.log("c")
  }
  
  refreshChats() {
    this.element.src = "";
  }

  toggleNewChatForm(e) {
    this.formTarget.classList.toggle("hidden");
  }

}