import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.setupGallery()
  }

  setupGallery() {

    // wrap gallery images with turbo links
    document.querySelectorAll(".gallery-block img").forEach((el) => {
      const wrapper = document.createElement("a")
      const src = el.src.split("/")
      const signedId = src[src.length-2]
      wrapper.href = `/attachments/${signedId}`
      wrapper.setAttribute("data-turbo-frame", "article_gallery")
      this.wrap(el, wrapper)
    })
  }

  wrap(el, wrapper) {
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
	}

}