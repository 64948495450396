import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {

  static targets = ["heart", "tooltipAdd", "tooltipRemove"]

  connect() {

    document.querySelectorAll("audio, video").forEach((mediaEl) => {
      mediaEl.addEventListener("error", (event) => {
        console.log("player error", event);
        if (event.target.error.code == 2 && mediaEl.currentTime > 3) {
          let currentTime = mediaEl.currentTime;
          mediaEl.src = mediaEl.querySelector("source").src;
          mediaEl.play();
          mediaEl.currentTime = currentTime;
          console.log("player source refreshed");
        }
      });
    });

    if (!!document.getElementById("article-card") && this.element.dataset.updated == "true" && Array.from(document.querySelectorAll(".media-player-inner")).pop() == this.element ) {
      var article = document.getElementById("article-card")
      var controllers = document.getElementById("article-card").dataset.controller
      document.getElementById("article-card").dataset.controller = ""
      setTimeout(() => {
        document.getElementById("article-card").dataset.controller = controllers
        this.element.dataset.updated = "false"
      });
    }

  }

  get currentUserID() {
    return document.body.dataset.userId
  }

  updatePlaylist() {
    this.heartTarget.classList.contains("in-playlist") ? this.remove() : this.add() 
  }

  add() {
    fetch(`/users/${this.currentUserID}/playlist`, {
      method: "post",
      credentials: "same-origin",
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify(
        {
          media_id: this.heartTarget.dataset.mediaId,
        } 
      )
    }).then((response) => {
      if (response.ok) {
        this.heartTarget.classList.add("in-playlist")
        this.tooltipRemoveTarget.classList.remove("hidden")
        this.tooltipAddTarget.classList.add("hidden")
        // this.heartTarget.classList.add("hidden")
      }
    })
  }

  remove() {
    fetch(`/users/${this.currentUserID}/playlist`, {
      method: "delete",
      credentials: "same-origin",
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify(
        {
          media_id: this.heartTarget.dataset.mediaId,
        } 
      )
    }).then((response) => {
      if (response.ok) {
        this.heartTarget.classList.remove("in-playlist")
        this.tooltipRemoveTarget.classList.add("hidden")
        this.tooltipAddTarget.classList.remove("hidden")
      }
    })
  }

}