import { Controller } from "stimulus";
import Rails from "rails-ujs";


export default class extends Controller {
  static targets = ["chevron", "sectionList", "toggleDraggableBtn"]

  connect() {}

  toggleDraggable() {
    document.querySelector(".steps-list").classList.toggle("draggable");

    this.toggleDraggableBtnTarget.classList.toggle("bg-purple-500");
    this.toggleDraggableBtnTarget.classList.toggle("bg-green-500");

  }

  toggleSection(e) {
    e.preventDefault()
    this.chevronTarget.classList.toggle("rotate-180")
    this.sectionListTarget.classList.toggle("hidden")
    this.saveSectionsStatus()
  }

  get currentUserID() {
    return document.body.dataset.userId
  }

  saveSectionsStatus() {
    if (!document.body.classList.contains("user-logged-in")) return;

    fetch(`/users/${this.currentUserID}/settings/courses_sections`, {
      method: "put",
      credentials: "same-origin",
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify(
        {
          course_id: this.sectionListTarget.dataset.course,
          open_sections: Array.from(document.querySelectorAll(".section-list:not(.hidden)")).map(el => parseInt(el.dataset.section)),
        } 
      )
    })
  }

}