import { Controller } from "stimulus"
// import { useHotkeys } from "stimulus-use"

export default class extends Controller {

  static targets = ["title", "attachments", "content", "editForm"]

  connect() {
    // useHotkeys(this, {
    //   "left": [this.navigateLeft],
    //   "right": [this.navigateRight],
    // });

    this.contentTarget.innerHTML = this.contentTarget.innerHTML.autoLink({class: "autolinked", target: "_blank"});

    document.addEventListener('click', function(e) {
      if (e.target.classList.contains('bullet-point-checkbox')) {
        var checked = e.target.checked;

        if (checked) {
          e.target.parentElement.querySelector('.bullet-point-input').classList.add("line-through");
        } else {
          e.target.parentElement.querySelector('.bullet-point-input').classList.remove("line-through");
        }
        
      };
    });

    var that = this;
    this.element.querySelector(".kanban-card-expire-area input[type='checkbox']").addEventListener('click', function(e) {
      if (e.target.checked == true) {
        that.element.querySelector(".datetime-selector-area").classList.remove("hidden");
      } else {
        let div = that.element.querySelector(".datetime-selector-area");
        div.classList.add("hidden");
        div.querySelector("button[type='submit']").click();
      }

    });


  }

  toggleEditForm(e) {
    if (e.target.classList.contains("autolinked")) return;
    
    e.preventDefault();

    // document.querySelector("#kanban_card_title").value = this.titleTarget.textContent;
    // document.querySelector("#kanban_card_content").value = this.contentTarget.textContent;
    
    this.titleTarget.classList.toggle("hidden");
    this.attachmentsTarget.classList.toggle("hidden");
    this.contentTarget.classList.toggle("hidden");
    this.editFormTarget.classList.toggle("hidden");
    this.element.classList.toggle("kanban-card-modal");
  }


  navigateLeft() {
    let columnID = this.element.dataset.column;
    let column = document.querySelector(`.kanban-column[data-kanban-column-id="${columnID}"]`);
    let columnCards = [...column.querySelectorAll('.kanban-card')];
    let currentCardIndex = columnCards.findIndex((cardEl) => cardEl.dataset.kanbanCardId == this.element.dataset.kanbanCardId);
    
    let prevCard = columnCards[currentCardIndex-1]
    if (!!prevCard) prevCard.querySelector('a.kanban-card-inner').click();
  }

  navigateRight() {
    let columnID = this.element.dataset.column;
    let column = document.querySelector(`.kanban-column[data-kanban-column-id="${columnID}"]`);
    let columnCards = [...column.querySelectorAll('.kanban-card')];
    let currentCardIndex = columnCards.findIndex((cardEl) => cardEl.dataset.kanbanCardId == this.element.dataset.kanbanCardId);
  
    let nextCard = columnCards[currentCardIndex+1]
    if (!!nextCard) nextCard.querySelector('a.kanban-card-inner').click();
  }

}