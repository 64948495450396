// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Notifier } from '@airbrake/browser';
import "chartkick/chart.js";
import "flag-icons/css/flag-icons.min.css"

Rails.start()
ActiveStorage.start()

import "controllers"
import "stylesheets/application"
// import TimeMe from "timeme.js"
import "autolink"

import "track"

const airbrake = new Notifier({
  projectId: 1,
  projectKey: 'ff52cbb2743e25122f8bd8a23fd2600d',
  host: 'https://bugtracker.acd.academy',
  remoteConfig: false,
  keysBlocklist: [
    'password',
    /secret/,
  ]
});

airbrake.addFilter((notice) => {
  notice.context.environment = process.env.RAILS_ENV;
  return notice;
});


window.onerror = function(error, url, lineNumber) {
  airbrake.notify(JSON.stringify(error, url, lineNumber));
  return true;
};

window.copySupportEmailAddress = function(ev) {
  navigator.clipboard.writeText("info@accademiadicoscienzadimensionale.it");
  document.querySelectorAll(".copied-email-tooltip").forEach((e) => {
    e.classList.remove("hidden");

    setTimeout(() => {
      e.classList.add("hidden");
    }, 2500);
  });
}

