import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [];

  connect() {
    if (document.querySelectorAll('.award-notification').length > 1) {
      var notifications = Array.from(document.querySelectorAll('.award-notification'))
      notifications.pop()
      notifications.forEach((notification, index) => {
        notification.style.bottom = "100px"
      })
    }

    setTimeout(() => { this.close() }, 5200)

  }

  close() {
    this.element.classList.remove("animate__backInRight")
    this.element.classList.add("animate__backOutRight")
    
    setTimeout(() => {
      this.element.classList.add("hidden")
    }, 600);
   

  }

}