import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["secondary", 
                    "secondaryBtn", 
                    "secondaryBtnDesktop", 
                    "secondaryBtnIcon", 
                    "secondaryBtnIconDesktop",
                    "booksList"]

  connect() {
    this.menu = document.getElementById("side_menu")
    this.screenWidth = window.screen.width
    this.screenHeight = window.screen.height
    this.isMobile = Math.min(this.screenWidth, this.screenHeight) < 768

    if (this.hasBooksListTarget) {
      document.addEventListener('click', (e) => {
        if (e.target.closest('.books-list-container')) return;
        this.booksListTarget.classList.add("hidden")
      })
    }

    if (this.isMobile) {
      document.querySelectorAll(".secondary-menu-modal .modal-content a").forEach((el) => { el.removeAttribute("target") })
    }
    // secondary-menu-modal
  }
  
  open() {
    this.menu.classList.remove("-translate-x-full")
    setTimeout(() => {
      this.menu.classList.remove("bg-opacity-0")
      this.menu.classList.add("bg-opacity-40")
    }, 80);

    document.body.classList.add("overflow-y-hidden")
  
  }

  close() {
    this.menu.classList.remove("bg-opacity-40")
    this.menu.classList.add("bg-opacity-0")
    setTimeout(() => {
      this.menu.classList.add("-translate-x-full")
    }, 80);
    
    document.body.classList.remove("overflow-y-hidden")
  }

  toggleSecondaryDesktop() {

    var menu = document.querySelector('#secondary_menu_desktop')
    
    if (menu.classList.contains("lg:hidden")) {
       menu.classList.add("lg:flex")
       menu.classList.remove("lg:hidden")
      this.secondaryBtnIconDesktopTarget.classList.add("rotate-180")
    } else {
       menu.classList.add("lg:hidden")
       menu.classList.remove("lg:flex")
      this.secondaryBtnIconDesktopTarget.classList.remove("rotate-180")
    }
  }

  toggleSecondary() {
    this.secondaryTarget.classList.toggle("hidden")

    if (this.secondaryTarget.classList.contains("hidden")) {
      this.secondaryBtnIconTarget.classList.remove("rotate-180")
    } else {
      this.secondaryBtnIconTarget.classList.add("rotate-180")
    }
  }


  openModal(e) {
    e.preventDefault();
    document.querySelectorAll(`.secondary-menu-modal:not([data-modal="${e.target.dataset.modal}"])`).forEach((el) => { el.classList.add("hidden") })
    var modalToOpen = document.querySelector(`.secondary-menu-modal[data-modal="${e.target.dataset.modal}"]`);
    modalToOpen.classList.toggle("hidden");
  }

  toggleBooksList(e) {
    e.preventDefault()
    if (this.hasBooksListTarget) {
      this.booksListTarget.classList.toggle("hidden")
    }
  }

  toggleBooksListModal(e) {
    e.preventDefault()
    document.querySelector(".books-list-modal").classList.toggle("hidden")
  }

}