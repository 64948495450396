import { Controller } from "stimulus"
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {

  static targets = ["scrollArea", "toggleSortableButton"];

  connect() {

    const slider = document.querySelector('#kanbanScroll:not(.kanban-card):not(.kanban-column)');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      if (e.target.id != "kanbanScroll") return;
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });

    var isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));

    this.sortable = Sortable.create(this.scrollAreaTarget, {
      draggable: ".kanban-column",
      animation: 250,
      disabled: isTouchDevice,
      ghostClass: 'ghost-kanban-column',
      swapThreshold: 1,
      onEnd: function(evt) {
        let columnsOrder = [...document.querySelectorAll('.kanban-column')].map((c) => c.dataset.kanbanColumnId)
        
        let params = {columns_order: columnsOrder}

        fetch('/kanbans/' + document.querySelector('#kanban').dataset.kanbanId + '/sort-columns', {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
            body: JSON.stringify(params)
          }).then(response => {
            if (response.ok) {
              console.log("position: updated")
            }
          })


      }
    })
  
    window.sortableCols = this.sortable;

    if (isTouchDevice) {
      this.toggleSortableButtonTarget.classList.remove("hidden");
    }  



    document.body.addEventListener("focusout", function(e){
      if (e.target.classList.contains("kanban-column-title-input")) {
        let form = e.target.closest("form");
        form.querySelector("button.update-kanban-column-title-btn").click();
      }
    });

    if (document.querySelector(".chats-box").classList.contains("h-12")) {
      document.querySelector(".hide-chats-box .close-chats-btn").classList.add("rotate-180");
    }

    document.body.addEventListener("click", function(e) {
      if (e.target.classList.contains("hide-chats-box")) {
        var chatsBox = document.querySelector(".chats-box");
        chatsBox.classList.toggle("lg:h-3/5");
        chatsBox.classList.toggle("h-4/5");
        chatsBox.classList.toggle("h-12");
        // chatsBox.classList.toggle("bottom-8");
        chatsBox.querySelector(".close-chats-btn").classList.toggle("rotate-180");
        chatsBox.querySelector(".back-to-chats-btn").classList.toggle("hidden");
      }
    });



  }


  showExpiringCards(e) {
    e.preventDefault();
    document.querySelector(".expiring-cards-box").classList.toggle("hidden");
  }

  


  toggleAllSortable() {

    if (!this.sortable.options.disabled) {
      this.toggleSortableButtonTarget.classList.remove("bg-green-200")
      this.toggleSortableButtonTarget.classList.add("bg-gray-200")
    } else {
      this.toggleSortableButtonTarget.classList.add("bg-green-200")
      this.toggleSortableButtonTarget.classList.remove("bg-gray-200")

    }


    // columns
    this.sortable.option("disabled", !this.sortable.options.disabled);

    // cards
    window.sortableCards.forEach((sortable) => { sortable.option("disabled", !sortable.options.disabled) });


    
  }

}