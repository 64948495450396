import { DirectUpload } from "@rails/activestorage"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.setupDirectUploads()
  }

  setupDirectUploads() {
    const input = this.inputTarget

    const onDrop = (event) => {
      event.preventDefault()
      const files = event.dataTransfer.files;
      Array.from(files).forEach(file => uploadFile(file))
    }

    input.addEventListener('change', (event) => {
      Array.from(input.files).forEach(file => uploadFile(file))
      console.log("changed input")
      input.value = null
    })

    const uploadFile = (file) => {
   
      const url = input.dataset.directUploadUrl
      const blobUrl = input.dataset.blobUrl
      const upload = new DirectUpload(file, url)

      upload.create((error, blob) => {
        if (error) {
          console.log(error)
        } else {
          // Add an appropriately-named hidden input to the form with a
          //  value of blob.signed_id so that the blob ids will be
          //  transmitted in the normal upload flow
          const hiddenField = document.createElement('input')
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("value", blob.signed_id);
          hiddenField.name = input.name
          this.element.appendChild(hiddenField)

          const imageBlobUrl = this.createBlobUrl(blobUrl, blob.signed_id, blob.filename)
          const imagePreview = document.createElement("img")
          imagePreview.src = imageBlobUrl
          imagePreview.width = 50
          imagePreview.height = 50

          this.element.appendChild(imagePreview)
        }
      })
    }

   
  } // setup

    createBlobUrl(blobUrl, signedId, filename) {
      return blobUrl
        .replace(":signed_id", signedId)
        .replace(":filename", encodeURIComponent(filename))
    }

}


