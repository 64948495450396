import { Controller } from "stimulus";

export default class extends Controller {
 
  connect() {
    

    var chatID = this.element.dataset.chat;
    console.log("chatid", chatID)
    var firstMessage = document.querySelector(`#kanban_chat_${chatID}_messages .kanban-chat-message`);
    console.log("firstmessage", firstMessage);
    this.element.parentElement.action = this.element.parentElement.action+"&start="+firstMessage.dataset.message;
  }

}