import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.querySelector(`.kanban-card-inner`).addEventListener("click", function(e) {
      this.classList.remove("bg-yellow-200");
      this.classList.add("bg-white");
      let col = this.parentElement.parentElement.parentElement
      col.classList.remove("bg-indigo-400")
      col.classList.add("kanban-column-bg")
    });
  }
}