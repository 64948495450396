import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["input", "submit"]

  connect() {
    document.body.addEventListener("click", (e) => {
      if (!e.target.closest("#articles_search_inner")) {
        this.clearArticlesSuggestions()
      }
    })
  }

  reset() {
    if (this.inputTarget.value.length == 0) {
      this.submitTarget.click()
    }
  }

  resetArticlesSearch() {
    if (this.inputTarget.value.length == 0) {
      this.clearArticlesSuggestions()
    }
  }

  clearArticlesSuggestions() {
    var suggestions = document.querySelector("turbo-frame#articles_search_suggestions")
    suggestions.innerHTML = ""
    suggestions.removeAttribute("src")
  }

  toggleArticlesSearch() {
    document.querySelector("#articles_search").classList.toggle("hidden")
    if (!document.querySelector("#articles_search").classList.contains("hidden")) {
      document.querySelector("#articles_search input").focus()
    }
  }

  close() {
    document.querySelector("#articles_search").classList.add("hidden")
  }
  
      
}