import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {
   this.initContextMenu();
 }

  connect() {
    this.element[this.identifier] = this
    this.screenWidth = window.screen.width
    this.screenHeight = window.screen.height
    this.isMobile = Math.min(this.screenWidth, this.screenHeight) < 768
    if (this.isMobile) {
      this.onLongPress(document.getElementById("article_card_content"), (e) => {
        this.openMenu(e)
      })
    }
  }

  
  onLongPress(element, callback) {
    let timer;
  
    element.addEventListener('touchstart', (e) => { 
      timer = setTimeout(() => {
        timer = null;
        callback(e);
      }, 500);
    });
  
    function cancel() {
      clearTimeout(timer);
    }
  
    element.addEventListener('touchend', cancel);
    element.addEventListener('touchmove', cancel);
  }


 initContextMenu() {
   const menu = document.querySelector(".menu");
    let menuVisible = false;

    const toggleMenu = command => {
      menu.style.display = command === "show" ? "block" : "none";
      menuVisible = !menuVisible;
    };

    this.setPosition = ({ top, left }) => {
      menu.style.left = this.isMobile ? `${(this.screenWidth/2)-112}px` : `${left}px`;
      menu.style.top =  `${top}px`;
      toggleMenu("show");
      // document.querySelector("body").classList.add("scroll-disabled")
    };

    window.addEventListener("click", e => {
      toggleMenu("hide");
      //  document.querySelector("body").classList.remove("scroll-disabled")
    });
    
    window.addEventListener("scroll", e => {
      toggleMenu("hide");
    })

    document.getElementById("article_card_content").addEventListener("contextmenu", e => {
      this.openMenu(e)
    });
    

 }

 openMenu(e) {
  if (!!document.querySelector(".active-mode")) return;
  e.preventDefault();

  var topCorrected = e.pageY;
  var leftCorrected = e.pageX;

  if (e.pageY > (this.screenHeight-50)) {
    topCorrected = e.pageY - (e.pageY - (this.screenHeight-50))
  }

  if (e.pageX > (this.screenWidth-50)) {
    leftCorrected = e.pageX - (e.pageX - (this.screenWidth-50))
  }

  const origin = {
    left: e.pageX,
    top: e.pageY,
  };
  this.setPosition(origin);
  return false;
 }

 copy(e) {
  e.preventDefault()
  e.stopPropagation()
  var selectedText = window.getSelection()
  if (selectedText == undefined) return
  navigator.clipboard.writeText(selectedText.toString())
 }

 toggleTakeNoteMode() {
   const event = new CustomEvent("toggle-take-note-mode")
   window.dispatchEvent(event)
 }

 toggleDiaryPageModal() {
   const event = new CustomEvent("toggle-diary-page-modal")
   window.dispatchEvent(event)
 }

 toggleQuestionsModal() {
   const event = new CustomEvent("toggle-questions-modal")
   window.dispatchEvent(event)
 }

 toggleQuickCommentModal() {
   const event = new CustomEvent("toggle-quick-comment-modal")
   window.dispatchEvent(event)
 }

 toggleAskForClarificationMode() {
   const event = new CustomEvent("toggle-ask-for-clarification-mode")
   window.dispatchEvent(event)
 }

 toggleFeedbackMode() {
   const event = new CustomEvent("toggle-feedback-mode")
   window.dispatchEvent(event)
 }

 toggleSiteFeedbackMode() {
  const event = new CustomEvent("toggle-site-feedback-mode")
  window.dispatchEvent(event)
}

}