import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["filterCategoriesModal"]

  connect() { 
    
    // infinite scrolling
    document.onscroll = function() {
      
      if(document.documentElement.scrollTop + window.innerHeight >= (document.documentElement.scrollHeight - 400 )) {
        let lastCard = document.querySelectorAll(".book-article-card")[document.querySelectorAll(".book-article-card").length-1]

        
        let btn = document.querySelector("#loadMoreBtn input");
        if (btn.dataset.loading == "true") return;
        
        btn.dataset.loading = "true";
        btn.click();
        
        setTimeout(() => {
          lastCard.scrollIntoView({ behavior: "smooth" });
        }, 300);

      }
    }



  }

  getCheckedCheckboxesFor(checkboxName) {
    var checkboxes = document.querySelectorAll('input[name="' + checkboxName + '"]:checked'), values = [];
    Array.prototype.forEach.call(checkboxes, function (el) {
      values.push(el.value);
    });
    return values;
  }

  filterStarredArticles(e) {
    // starred by user
    e.preventDefault()
    
    var btn = e.target.tagName == "A" ? e.target : e.target.closest("a");

    if (btn.dataset.active == "false") {
      document.querySelectorAll('.book-article-card:not(.starred-by-user)').forEach((articleCard) => {
        articleCard.classList.add("hidden")
      });
      btn.dataset.active = true;
    } else {
      document.querySelectorAll('.book-article-card:not(.starred-by-user)').forEach((articleCard) => {
        articleCard.classList.remove("hidden")
      });
      btn.dataset.active = false;
    }
  }


  filterSuggestedArticles(e) {
    // suggested by Angel
    e.preventDefault()
  
    console.log(e.target);
    var btn = e.target.tagName == "A" ? e.target : e.target.closest("a");

    if (btn.dataset.active == "false") {
      document.querySelectorAll('.book-article-card:not(.suggested)').forEach((articleCard) => {
        articleCard.classList.add("hidden")
      });
      btn.dataset.active = true;
    } else {
      document.querySelectorAll('.book-article-card:not(.suggested)').forEach((articleCard) => {
        articleCard.classList.remove("hidden")
      });
      btn.dataset.active = false;
    }
  }


  addStarredClassToArticle(e) {
    var el = e.target.parentElement.parentElement;
    var shouldAddStarredClass = !el.classList.contains("heart-active");
    var articleId = el.dataset.articleId;
    
    if (shouldAddStarredClass) {
      document.querySelector(`.book-article-card[data-article-id="${articleId}"]`).classList.add("starred-by-user");
    } else {
      document.querySelector(`.book-article-card[data-article-id="${articleId}"]`).classList.remove("starred-by-user");
    }
  }

  addSuggestedClassToArticle(e) {
    var el = e.target.parentElement.parentElement;
    var articleId = el.dataset.articleId;

    if (el.classList.contains("star-active")) {
      el.classList.remove("star-active");
      document.querySelector(`.book-article-card[data-article-id="${articleId}"]`).classList.remove("suggested");
    } else {
      el.classList.add("star-active");
      document.querySelector(`.book-article-card[data-article-id="${articleId}"]`).classList.add("suggested");
    }
  }


  filterArticles(e) {
    
    var clickedCheckElement = e.target;
    var clickedCheck = e.target.value;
    var clickAction = e.target.checked ? "check" : "uncheck";

    // console.log(`selected checkbox n. ${clickedCheck} - cat. name ${clickedCheckElement.dataset.categoryName} - action: ${clickAction}"`)

    if (["7", "9"].includes(clickedCheck)) {
      var targetCheck = clickedCheck == "7" ? "9" : "7"
      document.querySelector(`input[name="category_filterCategoriesModal"]#filterCategory${targetCheck}`).checked = clickAction == "check"
    } else if (clickedCheck == "" && clickedCheckElement.dataset.categoryName == "altro") {
      ["7", "9", "10"].forEach((catNumber) => {
        document.querySelector(`input[name="category_filterCategoriesModal"]#filterCategory${catNumber}`).checked = clickAction == "check"
      })
    }


    var checkedCategories = this.getCheckedCheckboxesFor("category_filterCategoriesModal");
    
    var turboFrameEl = document.getElementById("bookArticleCardsListTurboFrame");
    // TO-DO change url !!!
    turboFrameEl.src = `/books/1?exclude_categories=${checkedCategories.join(",")}`


    // document.querySelectorAll('.book-article-card').forEach((articleCard) => {
    //   articleCard.classList.remove("hidden");
    // });

    // checkedCategories.forEach((category) => {
    //   document.querySelectorAll(`.book-article-card.category-${category}`).forEach((articleCard) => {
    //     articleCard.classList.add("hidden");
    //   });

    //   document.querySelector(`#category${category}`).checked = false;

    // });

  }


  filterArticlesByTag(e) {
    var checkedTags = this.getCheckedCheckboxesFor("tag_filterTagsModal");

    console.log(checkedTags)

    var turboFrameEl = document.getElementById("bookArticleCardsListTurboFrame");
    // TO-DO change url !!!
    turboFrameEl.src = `/books/1?tags=${checkedTags.join(",")}`

    // document.querySelectorAll('.book-article-card').forEach((articleCard) => {

    //   checkedTags.length == 0 ? articleCard.classList.remove("hidden") : articleCard.classList.add("hidden");
      
    // });

    // checkedTags.forEach((tag) => {
    //   document.querySelectorAll(`.book-article-card.tag-${tag}`).forEach((articleCard) => {
    //     articleCard.classList.remove("hidden");
    //   });

    // });

  }

  openOptionsModal(e) {
    e.preventDefault();
    document.querySelector("#optionsModal").classList.remove("hidden");
  }

}