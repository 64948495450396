import { Controller } from "stimulus";
import Rails from "rails-ujs";


export default class extends Controller {

  static targets = ["content", "adminButtons"];

  connect() {
    var isUser = document.querySelector("body").classList.contains("r-user");

    if (["admin", "staff", "helper"].includes(document.querySelector('body').dataset.userRole)) {
      var messageAdminBtns = this.element.querySelector(".message-admin-btns");
      if (messageAdminBtns != null) messageAdminBtns.classList.remove("hidden");
    }
    

    if (!this.hasContentTarget) return;
    this.contentTarget.innerHTML = this.contentTarget.innerHTML.autoLink({target: "_blank", linkAttr: { 'data-link': true }});

    if (!isUser && this.element.dataset.author == "user") {
      var messageUserName = this.element.querySelector(".message-user-name");
      var userName = messageUserName.textContent;
      messageUserName.innerHTML = '<a class="underline" target="_blank" href="/users/' + messageUserName.dataset.user + '">' + userName + '</a>';
    }
  }

}