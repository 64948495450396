import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ["showGroupSearchButton", 
                    "showUserSearchButton", 
                    "showMembersListButton",
                    "searchUserTextarea", 
                    "searchGroupTextarea", 
                    "searchResults",
                    "resultsList",
                    "saveModalButton",
                    "membersListTurboFrameTag",
                    "modalFooter",
                    "savePermissionGroupUsersButton",
                    "togglePermissionGroupEditFormButton",
                    "permissionGroupEditForm",
                    "submitFormButton"]


  resetSearchResults() {
    document.querySelectorAll(".search-results-area").forEach(el => el.innerHTML = "")
  }     

  submitForm() {
    this.submitFormButtonTarget.value = "Caricamento..."
    // this.submitFormButtonTarget.setAttribute("disabled", true)
    this.submitFormButtonTarget.style.width = "160px"
    this.searchResultsTarget.innerHTML = ""
  }

  togglePermissionGroupEditForm() {
   if (this.permissionGroupEditFormTarget.classList.contains("is-hidden")) {
      this.permissionGroupEditFormTarget.classList.remove("is-hidden")
      this.permissionGroupEditFormTarget.removeAttribute("src", '')
   } else {
      this.permissionGroupEditFormTarget.classList.add("is-hidden")
   }
  }

  changeModalScreen(event) {
     
    document.querySelectorAll(`.permissions-modal-change-screen-button:not([data-screen="${event.target.dataset.screen}"])`).forEach(el => el.classList.remove("active-button"))
    // document.querySelectorAll(".permissions-modal-content .article-textarea-container").forEach(el => el.classList.add("is-hidden"))
    document.querySelector(`.permissions-modal-change-screen-button[data-screen="${event.target.dataset.screen}"]`).classList.add("active-button")
    document.querySelector(".permissions-modal-content turbo-frame#permissions-modal-members-list").classList.add("is-hidden")
    this.searchResultsTarget.innerHTML = ""
    
    switch (event.target) {
  
      case this.showGroupSearchButtonTarget:
        this.searchGroupTextareaTarget.classList.remove("is-hidden")
        this.searchUserTextareaTarget.classList.add("is-hidden")
        document.querySelector("#permissions-modal-members-list").setAttribute('src', "")
        this.modalFooterTarget.classList.remove("is-hidden")
        break;

      case this.showUserSearchButtonTarget:
        this.searchUserTextareaTarget.classList.remove("is-hidden")
        this.searchGroupTextareaTarget.classList.add("is-hidden")
        document.querySelector("#permissions-modal-members-list").setAttribute('src', "")
        this.modalFooterTarget.classList.remove("is-hidden")
        break;
      
      case this.showMembersListButtonTarget:
        this.searchUserTextareaTarget.classList.add("is-hidden")
        this.searchGroupTextareaTarget.classList.add("is-hidden")
        document.querySelector(".permissions-modal-content turbo-frame#permissions-modal-members-list").classList.remove("is-hidden")
        this.modalFooterTarget.classList.add("is-hidden")
        break;

      default:
        break;
    }
  }

  showMembersList() {
    document.querySelectorAll(".permissions-modal-change-screen-button:not([data-screen='list'])").forEach(el => el.classList.remove("active-button"))
    document.querySelector(".permissions-modal-change-screen-button[data-screen='list']").classList.add("active-button")
    document.querySelectorAll(".permissions-modal-content textarea").forEach(el => el.classList.add("is-hidden"))
    this.searchResultsTarget.innerHTML = ""
  }

  showUserSearch() {
    document.querySelectorAll(".permissions-modal-change-screen-button:not([data-screen='users'])").forEach(el => el.classList.remove("active-button"))
    document.querySelector(".permissions-modal-change-screen-button[data-screen='users']").classList.add("active-button")
    document.querySelectorAll(".permissions-modal-content textarea").forEach(el => el.classList.add("is-hidden"))
    document.querySelector(".permissions-modal-content .search-users-textarea").classList.remove("is-hidden")
    this.searchResultsTarget.innerHTML = ""
  }

  showGroupSearch() {
    document.querySelectorAll(".permissions-modal-change-screen-button:not([data-screen='groups'])").forEach(el => el.classList.remove("active-button"))
    document.querySelector(".permissions-modal-change-screen-button[data-screen='groups']").classList.add("active-button")
    document.querySelectorAll(".permissions-modal-content textarea").forEach(el => el.classList.add("is-hidden"))
    document.querySelector(".permissions-modal-content .search-groups-textarea").classList.remove("is-hidden")
    this.searchResultsTarget.innerHTML = ""
  }

  save() {
    var isUserSearch = !this.searchUserTextareaTarget.classList.contains("is-hidden")
    var isGroupSearch = !this.searchGroupTextareaTarget.classList.contains("is-hidden")

    var newGroupPermissions = this.searchGroupTextareaTarget.value
    var newUserPermissions = this.searchUserTextareaTarget.value

    this.saveModalButtonTarget.innerHTML = "Attendi..."
    

    // var permssionGroupsNewUsersList = Array.from(document.querySelectorAll("#permissions-modal-members-list textarea")).map(el => ({permission_group_name: el.dataset.permissionGroupName, new_users_list_raw: el.value}));


    fetch("/admin/save-permissions", {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
        body: JSON.stringify(
          {
            articleID: this.saveModalButtonTarget.dataset.articleId,
            newGroupPermissions: newGroupPermissions, 
            newUserPermissions: newUserPermissions,
          }
        )
      }).then(response => {
        if (response.ok) {
           document.getElementById("modal-window").remove();
           document.getElementById("permissions-modal").removeAttribute("src");
        }
      })
  }

  savePermissionGroupUsers() {
    var newUserPermissions = this.searchUserTextareaTarget.value
    this.searchResultsTarget.innerHTML = ""


    fetch("/admin/save-permission-groups-users", {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
        body: JSON.stringify(
          {
            permissionGroupName: this.searchUserTextareaTarget.dataset.permissionGroupName,
            newUserPermissions: newUserPermissions, 
          }
        )
      }).then(response => {
         if (response.ok) {
          this.savePermissionGroupUsersButtonTarget.classList.add("button-success");
          this.savePermissionGroupUsersButtonTarget.innerHTML = "Fatto!";
          var that = this;
          setTimeout(function(){ 
            that.savePermissionGroupUsersButtonTarget.classList.remove("button-success");
            that.savePermissionGroupUsersButtonTarget.innerHTML = '<i class="fe fe-check-circle-o"></i> Salva';
           }, 3000);
        }
      })


  }


  search() {
    var isUserSearch = !this.hasSearchUserTextareaTarget ? false : !this.searchUserTextareaTarget.classList.contains("is-hidden")
    var isGroupSearch = !this.hasSearchGroupTextareaTarget ? false : !this.searchGroupTextareaTarget.classList.contains("is-hidden")
    var permissionGroupName = null;
    if (isUserSearch) {
      if (!!this.searchUserTextareaTarget.dataset.permissionGroupName) {
        permissionGroupName = this.searchUserTextareaTarget.dataset.permissionGroupName;
      }
    }


    var queryRaw = isUserSearch ? this.searchUserTextareaTarget.value : this.searchGroupTextareaTarget.value
    if (queryRaw.length == 0) this.searchResultsTarget.innerHTML = ""
    
    if (!queryRaw) return;
    var currentItems = queryRaw.split(";").map(item => item.trim())
    var query = currentItems.pop().trim()
    
    if (query && query.length > 2) {
      fetch(`/admin/permissions/search?query=${query}&currentItems=${JSON.stringify(currentItems)}&searchType=${isUserSearch ? "users" : "groups"}${permissionGroupName != null ? `&permissionGroupName=${permissionGroupName}` : ''}`)
        .then(response => {
          return response.text()
        })
        .then(html => {
          this.searchResultsTarget.innerHTML = html
        })
    } else {
      this.searchResultsTarget.innerHTML = ""
    }
  }

  autocompleteItemInTextarea(event, keyboardSelectedItem=null) {
    var isUserSearch = this.hasSearchUserTextareaTarget && !this.searchUserTextareaTarget.classList.contains("is-hidden")
    var isGroupSearch = this.hasSearchGroupTextareaTarget && !this.searchGroupTextareaTarget.classList.contains("is-hidden")
    var searchTextareaTarget = isUserSearch ? this.searchUserTextareaTarget : this.searchGroupTextareaTarget


    var currentItems = searchTextareaTarget.value.split(";").map(item => item.trim())
    currentItems.pop()
    var selectedItem = keyboardSelectedItem == null ? event.target.dataset.itemname : keyboardSelectedItem
    currentItems.push(selectedItem)
    
    var currentItemsUnique = Array.from(new Set(currentItems.map(JSON.stringify)), JSON.parse)
    
    // if (searchTextareaTarget.value.trim().slice(-1) != ";") {
      
    // }

    searchTextareaTarget.value = currentItemsUnique.join("; ").trim() + "; "
    
    this.searchResultsTarget.querySelector(`.results-list--result[data-itemname="${selectedItem}"]`).classList.add("selected-item")
    // this.searchResultsTarget.innerHTML = ""

    // searchTextareaTarget.focus()
  }

  selectResultWithKeyboard(event) {
    if (this.searchResultsTarget.innerHTML === "") return;

    var isKeyUp = event.keyCode == 38;
    var isKeyDown = event.keyCode == 40;
    var isKeyEnter = event.keyCode == 13;

    if (isKeyUp || isKeyDown) {
      event.preventDefault()

       var lastItemIndex = this.resultsListTarget.querySelectorAll('.results-list--result').length-1

       if (this.resultsListTarget.querySelectorAll('.results-list--result[data-arrows-selected="true"]').length == 0) { // not selected
  
       if (isKeyDown) {
         this.resultsListTarget.querySelector('.results-list--result[data-arrows-selected="false"][data-item-index="0"]').dataset.arrowsSelected = true
       } else if (isKeyUp) {
         this.resultsListTarget.querySelector('.results-list--result[data-arrows-selected="false"][data-item-index="' + lastItemIndex + '"]').dataset.arrowsSelected = true
       }
        
      } else {
        var currentItemSelected = this.resultsListTarget.querySelector('.results-list--result[data-arrows-selected="true"]')
        var currentItemSelectedIndex = parseInt(currentItemSelected.dataset.itemIndex)
        var nextItemIndex;

        if (isKeyDown) {
          nextItemIndex = currentItemSelectedIndex == lastItemIndex ? 0 : (currentItemSelectedIndex+1)
        } else if (isKeyUp) {
          nextItemIndex = currentItemSelectedIndex == 0 ? lastItemIndex : (currentItemSelectedIndex-1)
        }


        currentItemSelected.dataset.arrowsSelected = false
        this.resultsListTarget.querySelector('.results-list--result[data-arrows-selected="false"][data-item-index="' + nextItemIndex + '"]').dataset.arrowsSelected = true
      }
    }

    if (isKeyEnter) {
      var currentItemSelected = this.resultsListTarget.querySelector('.results-list--result[data-arrows-selected="true"]')
      this.autocompleteItemInTextarea(event, currentItemSelected.dataset.itemname)
      event.preventDefault()
    }
  }

}