import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["deleteBtn", "status"];

  connect() {

    if ( document.querySelectorAll(`.kanban-chat-message[data-message="${this.element.dataset.message}"]`).length > 1 ) {
      this.element.remove();
    }

    if (document.body.dataset.userUsername == this.element.dataset.user) {
      this.statusTarget.classList.remove("hidden");
    }

    var currentUser = document.body.dataset.userUsername;

    if (this.element.dataset.user == currentUser) {
      this.deleteBtnTarget.classList.remove("hidden");
      this.element.classList.add("rounded-br-none") 
    } else {
      this.element.classList.remove("bg-gray-100");
      this.element.classList.add("bg-blue-100");

      this.element.classList.add("rounded-tl-none")
    }

  }


}