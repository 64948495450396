import { Controller } from "stimulus";

export default class extends Controller {


  toggle() {

    let scrollTop = window.scrollY
    let docHeight = document.body.offsetHeight
    let winHeight = window.innerHeight
    let scrollPercent = scrollTop / (docHeight - winHeight)
    let scrollPercentRounded = Math.round(scrollPercent * 100)

    if (scrollPercentRounded >= 4) {
      this.element.classList.remove("opacity-0")
    } else {
      this.element.classList.add("opacity-0")
    }
  }

  scroll() {
    this.element.classList.add("opacity-0")
    window.scrollTo(0,0);
  }

}