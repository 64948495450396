import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {

  static targets = ["container", "source", "input", "elementToHide"]

  connect() {
    useClickOutside(this)
  }

  toggleInlineEdit() {
    if (!this.hasSourceTarget || !this.hasInputTarget || this.containerTarget.classList.contains("inline-edit-disabled")) return;
    this.sourceTarget.classList.toggle("hidden")
    this.inputTarget.classList.toggle("hidden")
    if (this.hasElementToHideTarget) {
      this.elementToHideTarget.classList.toggle("hidden")
    }
  }

  clickOutside(event) {
    if (!this.hasSourceTarget || !this.hasInputTarget || this.containerTarget.classList.contains("inline-edit-disabled")) return;
    this.sourceTarget.classList.remove("hidden")
    this.inputTarget.classList.add("hidden")
  }

}