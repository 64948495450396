import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
                    "comment",
                    "nameInput",
                    "input",
                    "submit",
                    "modalSaveForLaterSubmit",
                    "modalSendNowSubmit",
                    "deleteCommentButton", 
                    "inlineEditContainer",
                    "searchInput",
                    "submitSearch",
                    "cancelSearch",
                    "replyFormArea",
                    ]

  get currentUserID() {
    return document.body.dataset.userId
  }

  get currentUserUsername() {
    return document.body.dataset.userUsername;
  }

  get createdAt() {
    if (this.hasCommentTarget) {
      return new Date(parseFloat(this.commentTarget.dataset.createdAt))
    }
  }

  get timeElapsed() {
    return new Date() - this.createdAt
  }

  get minutesElapsed() {
    return Math.floor(this.timeElapsed / 60000)
  }

  get hoursElapsed() {
    return Math.floor(this.minutesElapsed / 60)
  }

  connect() {

    if (this.hasCommentTarget) {
      let isNew = this.commentTarget.dataset.newFromStream;
      let commentUserId = this.commentTarget.dataset.userId;

      if (isNew == "true" && commentUserId == this.currentUserID) {
        const event = new CustomEvent("new-comment-from-stream")
        window.dispatchEvent(event)
      }

    }

    if (this.hasNameInputTarget) {
      var isLoggedIn = document.body.classList.contains("user-logged-in")
      if (!isLoggedIn) {
        this.nameInputTarget.classList.remove("hidden") 
        this.nameInputTarget.required = true
      }
    }


      if (this.hasCommentTarget) {

        if ((this.hoursElapsed < 1 && this.commentTarget.dataset.userId == this.currentUserID) || document.body.classList.contains("r-admin")) {
          this.inlineEditContainerTarget.classList.remove("inline-edit-disabled")
        }

        if ((this.hoursElapsed < 24 && this.commentTarget.dataset.userId == this.currentUserID) || document.body.classList.contains("r-admin")) {
          this.deleteCommentButtonTarget.classList.remove("is-hidden")
        }
    }
    
  }

  checkMinWords() {
    if (document.body.classList.contains("r-admin")) {
      if (this.hasSubmitTarget) {
        this.submitTarget.removeAttribute("disabled")
        return;
      }
    }
    
    var wordsCount = this.inputTarget.value.length > 0 ? this.inputTarget.value.split(" ").length : 0
    if (wordsCount >= 15) {
      if (this.hasSubmitTarget) this.submitTarget.removeAttribute("disabled")
      // if (this.hasModalSaveForLaterSubmitTarget) this.modalSaveForLaterSubmitTarget.removeAttribute("disabled")
      if (this.hasModalSendNowSubmitTarget) this.modalSendNowSubmitTarget.removeAttribute("disabled")
    } else {
      if (this.hasSubmitTarget) this.submitTarget.setAttribute("disabled", "disabled")
      // if (this.hasModalSaveForLaterSubmitTarget) this.modalSaveForLaterSubmitTarget.setAttribute("disabled", "disabled")
      if (this.hasModalSendNowSubmitTarget) this.modalSendNowSubmitTarget.setAttribute("disabled", "disabled")
    }
  }

  resetSearch() {
    if (this.searchInputTarget.value == "") {
      this.cancelSearch();
    }
  }

  cancelSearch() {
    this.searchInputTarget.value = ""
    this.submitSearchTarget.click()
  }

  submit(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.submitTarget.click()
    }
  }

  toggleReplyFormArea(e) {
    // [admin]
    this.replyFormAreaTarget.classList.toggle("hidden")
  }


}