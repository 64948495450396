import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ["input", "editorGalleryBlock"];

  connect() {

    const dropzoneModal = document.querySelector(".dropzone-modal")
    if (dropzoneModal) {
      dropzoneModal.addEventListener("click", (e) => {
        if (!e.target.closest("article")) e.target.classList.add("hidden")
      })
    }

    this.dropZone = createDropZone(this);
    this.dropZone.options.dictDefaultMessage = "Rilascia qui per caricare";
    this.dropZone.options.dictFileTooBig = "File troppo grande ({{filesize}}MiB). La dimensione massima è di {{maxFilesize}}MiB.";
    this.dropZone.options.dictInvalidFileType = "Il tipo di file non è consentito.";
    this.dropZone.options.dictResponseError = "Il server ha risposto con il codice errore {{statusCode}}.";
    this.dropZone.options.dictCancelUpload = "Annulla";
    this.dropZone.options.dictCancelUploadConfirmation = "Sei sicuro di voler annullare questo caricamento?";
    this.dropZone.options.dictRemoveFile = "Rimuovi";
    this.dropZone.options.dictMaxFilesExceeded = "Non puoi caricare altri file";
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    this.blobUrl = this.inputTarget.dataset.blobUrl
    
    this.sortableUploads = Sortable.create(
      document.querySelector('.dropzone.dropzone-default'), 
      { draggable: ".dz-preview",
        onUpdate: function(event) {
          
          const editorGalleryBlock = document.querySelector("#editor_gallery_block .gallery-block")
          editorGalleryBlock.innerHTML = ""

          document.querySelectorAll(".dz-image-preview").forEach(el => {

            const image = document.createElement("img")
            image.id = el.dataset.signedId
            image.src = el.dataset.blobUrl
            editorGalleryBlock.appendChild(image)
            
          })
        

        }
        },
    )

  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      
      const imagesInEditor = Array.from(document.querySelectorAll(".gallery-block img"))
      var presentInEditor = false;

      if (imagesInEditor.length > 0) {
        presentInEditor = !!imagesInEditor.find(img => img.src == file.controller.galleryImage.src)
        console.log("present?", presentInEditor)
      }
      
      file.controller && !presentInEditor && removeElement(file.controller.hiddenInput);

      file.controller.galleryImage.remove()
      if (document.querySelectorAll(".dz-image").length == 0) {
        document.querySelector("#insert_gallery").classList.add("invisible")
      }
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    document.querySelector("#insert_gallery").addEventListener('click', () => {
      const editor = window.editor
      const content = document.querySelector("#editor_gallery_block .gallery-block-container").outerHTML
      const viewFragment = editor.data.processor.toView( content );
      const modelFragment = editor.data.toModel( viewFragment );
      editor.model.insertContent( modelFragment, editor.model.document.selection );
      document.querySelector(".dropzone-modal").classList.add("hidden")
      this.dropZone.removeAllFiles()
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        console.log(this.hiddenInput)
        this.emitDropzoneSuccess(attributes.signed_id, attributes.filename);
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    document.querySelector("#article_form_container > form").appendChild(input)
    // insertAfter(input, document.querySelector("#new_article"));
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess(signedId, filename) {
    this.file.status = Dropzone.SUCCESS;



    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);

    const editorGalleryBlock = document.querySelector("#editor_gallery_block .gallery-block")
    const uploadedImage = document.createElement("img")
    var blobUrl = createBlobUrl(this.source.blobUrl, signedId, filename)
    uploadedImage.id = `img_${signedId}`
    uploadedImage.src = blobUrl
    editorGalleryBlock.appendChild(uploadedImage)
    this.galleryImage = uploadedImage


    var preview = this.file.previewElement
    preview.setAttribute("data-blob-url", blobUrl)
    preview.setAttribute("data-signed-id", signedId)
    this.file.previewElement = preview

    document.querySelector("#insert_gallery").classList.remove("invisible")

  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  });
}

function createBlobUrl(blobUrl, signedId, filename) {
  return blobUrl
    .replace(":signed_id", signedId)
    .replace(":filename", encodeURIComponent(filename))
}