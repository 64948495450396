import { Controller } from "stimulus";
import { useClickOutside } from "stimulus-use"

export default class extends Controller {

  static targets = ["modal"]

  connect() {
    useClickOutside(this)
  }
  
  clickOutside(event) {
    event.preventDefault()
    this.modalTarget.parentElement.remove()
    document.querySelector(`turbo-frame#user_${this.modalTarget.dataset.userId}_ip_check_list`).setAttribute("src", "")
  }

}