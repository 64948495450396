import { Controller } from "stimulus";

export default class extends Controller {

  connect() {  
    document.body.classList.add("overflow-y-hidden")
  }

  disconnect() {
    document.body.classList.remove("overflow-y-hidden")
  }

  refresh() {
    document.querySelector("turbo-frame#chats_list").removeAttribute("src")
  }

}