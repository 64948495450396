import { Controller } from "stimulus";
import Rails from 'rails-ujs'

export default class extends Controller {

  static targets = ["input", "submit", "usernameTaken", "usernameAvailable"]

  checkUsernameAvailability() {
    if (this.inputTarget.value.length < 3) {
      this.usernameAvailableTarget.classList.add("invisible")
      this.usernameTakenTarget.classList.add("invisible")
      this.submitTarget.setAttribute("disabled", "disabled")
      return;
    }
      this.inputTarget.value = this.inputTarget.value.trim()

      fetch("/registrations/check-username-availability", {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
        body: JSON.stringify(
          {
            username: this.inputTarget.value.trim(),
          }
        )
      }).then(response => {
        if (response.ok) {
          this.usernameAvailableTarget.classList.remove("invisible")
          this.usernameTakenTarget.classList.add("invisible")
          this.submitTarget.removeAttribute("disabled")
        } else if (response.status == 406) {
          this.usernameAvailableTarget.classList.add("invisible")
          this.usernameTakenTarget.classList.remove("invisible")
          this.submitTarget.setAttribute("disabled", "disabled")
        }
      })

  }
  

}