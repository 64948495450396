import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    
    
  }

}