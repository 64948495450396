import { Controller } from "stimulus";
import Rails from "rails-ujs";


export default class extends Controller {

  static targets = ["input", "submit", "messagesList"]

  connect() {
    this.scrollMessagesList()

    this.setMessagesReadStatus();

    if (!!document.querySelector(".chat-notification-badge")) {  
      document.querySelector(".chat-notification-badge").classList.add("hidden")
    }
    
    document.addEventListener("turbo:before-stream-render", (e) => {
      var appended = e.target
      if (appended.target == "messages_list") {
        
        setTimeout(() => {
          this.scrollMessagesList()
        }, 200);

        this.markAsRead();
      }
    })

    document.body.classList.add("overflow-y-hidden")

    this.submitTarget.addEventListener("click", (e) => {
      document.querySelector('#chatResponderList').classList.add('hidden');
    })
  }

  disconnect() {
    document.body.classList.remove("overflow-y-hidden")
  }

  markAsRead() {
    var isUser = document.querySelector("body").classList.contains("r-user");

    if (!isUser) return;

    var currentUserID = document.querySelector("body").dataset.userId;

    fetch("/users/" + currentUserID + "/mark_support_chat_as_read", {
      method: "post",
      credentials: "same-origin",
      headers: {
        'Accept': "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then((response) => {
      if (response.ok) {
     
      }
    });
  }

  setMessagesReadStatus() {
    var isUser = document.querySelector("body").classList.contains("r-user");
    var messages = this.element.querySelectorAll(".private-message");
    if (messages.length == 0) return;
    [...messages].forEach((message) => {      
      var messageReadStatusIndicators = message.querySelector(".message-read-status");
      if (isUser && messageReadStatusIndicators != null) messageReadStatusIndicators.remove();
      if (message.dataset.author == "user") return;
      var chatID = message.dataset.chat;
      var chatModal = document.querySelector(".support-chat-modal[data-chat='" + chatID + "']");
      var userLastReadAt = new Date(chatModal.querySelector("#user_last_read_at").dataset.userLastReadAt * 1000);
      var messageSentAt = new Date(message.dataset.sentAt * 1000);
      var messageRead = userLastReadAt > messageSentAt;
      if (!isUser) message.querySelector(messageRead ? ".message-read" : ".message-unread").classList.remove("hidden")
    });
  }


  submit(e) {
    if (e.which == 13) {
      if (!e.shiftKey) {
        e.preventDefault();
        this.submitTarget.click()
     
        setTimeout(() => {
          this.inputTarget.focus()
          document.querySelector('#chatResponderList').classList.add('hidden');
        }, 200);

        if (!!document.querySelector(".chat-notification-badge")) {
          document.querySelector(".chat-notification-badge").classList.add("hidden")
        }
      }
    }
  }

  scrollMessagesList() {
    this.messagesListTarget.scrollTop = this.messagesListTarget.scrollHeight
  }

}