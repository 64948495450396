import { Controller } from 'stimulus'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ["sideMenu", "selectCategoryNotice"]

  connect() {

  }

  toggle(e) {
    e.preventDefault();
    this.sideMenuTarget.classList.toggle("-translate-x-full");
  }

  selectAllCategories(e) {
    e.preventDefault();
    var checkboxes = document.querySelectorAll("input[type='checkbox'][name='category']");
    var checkedCheckboxes = this.getCheckedCheckboxesFor("category");
    var areAllChecked = checkedCheckboxes.length == checkboxes.length;

    var checkboxes = checkboxes.forEach((checkbox) => {
      checkbox.checked = !areAllChecked;
    });
    this.filterArticles(e);
  }

  filterArticles(e) {
    
    console.log(e.target);
    console.log(this.getCheckedCheckboxesFor("category"));
    var checkedCategories = this.getCheckedCheckboxesFor("category");

    console.log(checkedCategories);

    
    var turboFrameEl = document.getElementById("bookArticleCardsListTurboFrame");
    // TO-DO change url !!!
    turboFrameEl.src = `/books/1?categories=${checkedCategories.join(",")}`


     
    // fetch(URL, {
    //   credentials: 'same-origin',
    //   headers: {
    //     'Accept': 'text/vnd.turbo-stream.html',
    //     'Content-Type': 'application/json',
    //     'X-CSRF-Token': Rails.csrfToken(),
    //   },
    //   }).then(response => {
    //     if (response.ok) {
    //       console.log("response: ok")
    //     }
    //   })



    // if ( checkedCategories.length == 1 ) {
    //   var mese1 = document.querySelector(".category-checkbox[data-category-name='Mese 1']").value;
    //   var mese2 = document.querySelector(".category-checkbox[data-category-name='Mese 2']").value;
    //   var mese3 = document.querySelector(".category-checkbox[data-category-name='Mese 3']").value;

    //   if ( [mese1, mese2, mese3].includes(checkedCategories[0]) ) {
    //     // month category selected

    //     var cards = [...document.querySelectorAll(".book-article-card")];
    //     cards.sort((a, b) => Number(a.dataset.monthPosition) - Number(b.dataset.monthPosition));
    //     var sortedCards = cards.reduce((a, el) => a + el.outerHTML, "");
    //     document.getElementById('bookArticleCardsList').innerHTML = sortedCards;

    //   }
    // }

    // document.querySelectorAll('.book-article-card').forEach((articleCard) => {
    //   articleCard.classList.add("hidden");
    //   this.selectCategoryNoticeTarget.classList.remove("hidden");
    // });


    // checkedCategories.forEach((category) => {
    //   document.querySelectorAll(`.book-article-card.category-${category}`).forEach((articleCard) => {
    //     articleCard.classList.remove("hidden");
    //     this.selectCategoryNoticeTarget.classList.add("hidden");
    //   });

    //   var filterCategory = document.querySelector(`#filterCategory${category}`);
    //   if (!!filterCategory) filterCategory.checked = false;

    // });
  }


  getCheckedCheckboxesFor(checkboxName) {
    var checkboxes = document.querySelectorAll('input[name="' + checkboxName + '"]:checked'), values = [];
    Array.prototype.forEach.call(checkboxes, function (el) {
      values.push(el.value);
    });
    return values;
  }

}