import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["modal", "content", "selectedText", "modeBar", "button", "confirmButton"]

  connect() {
    this.screenWidth = window.screen.width
    this.screenHeight = window.screen.height
    this.isMobile = this.screenWidth <= 768
  }
  
  toggleModal() {
    this.modalTarget.classList.toggle("hidden")
  }

  closeModal(e) {
    if (e.target.closest(".modal-article")) return;
    this.modalTarget.classList.add("hidden")
  }

  windowToggleModal() {
    const event = new CustomEvent("toggle-ask-for-clarification-modal")
    window.dispatchEvent(event)
  }

  
  toggleMode() {

    this.buttonTarget.addEventListener("click", e => {
      this.closeMode();
    })
  
    if (this.modeBarTarget.classList.contains("active-mode")) {

      this.closeMode()

    } else {
      this.modeBarTarget.classList.add("active-mode")
      document.body.classList.remove("select-none")

      document.querySelectorAll("#article-card p").forEach(el => el.classList.add("cursor-select-text-questions"))

      document.addEventListener("selectionchange", e => {
        if (!document.body.classList.contains("r-user")) return;
        let selection = document.getSelection()
        let selectionWordsCount = selection.toString().split(" ").length
        if (selectionWordsCount == 50) {
          // this.handleSelection(e);
        } else {
          if (selectionWordsCount > 80) {
            if (document.body.classList.contains("r-user")) {
              document.body.classList.add("select-none");
              document.body.classList.remove("select-none");
            }
            document.getSelection().removeAllRanges();
          }
        }
      });

      if (!this.isMobile) {
        document.body.addEventListener("mouseup", e => {
          var classList = e.target.classList;
          if (classList.contains("ask-for-clarification-btn") || classList.contains("ask-for-clarification-mode") || classList.contains("ask-for-clarification-textarea") || classList.contains("ask-for-clarification-write-area")) return;
          if (document.getSelection().toString().split(" ").length <= 80) this.handleSelection(e)
         })
      }


      if (this.isMobile) {
        this.confirmButtonTarget.classList.remove("hidden")
        document.addEventListener("selectionchange", e => {
          if (!document.body.classList.contains("r-user")) return;
          var textSelection = window.getSelection()
          if (textSelection != undefined && textSelection.toString() != "" && textSelection.toString().length > 3) {
        
              this.confirmButtonTarget.removeAttribute("disabled")
          } else {
            this.confirmButtonTarget.setAttribute("disabled", "disabled")
          }
        })
      }

    }
    
  }


  confirmSelection(e) {
    // if (!this.isMobile || window.getSelection() == undefined || window.getSelection().toString().trim() == "") return;
    this.handleSelection(e)
  }


    handleSelection(e) {
      if (!this.modeBarTarget.classList.contains("active-mode")) return;

      // var classList = document.querySelector("p.cursor-select-text").classList;

      // if (classList.contains("ask-for-clarification-btn") || classList.contains("ask-for-clarification-mode") || classList.contains("ask-for-clarification-textarea") || classList.contains("ask-for-clarification-write-area")) return;
      
      if (window.selectedText == undefined) window.selectedText = window.getSelection()
      // window.selectedText = window.getSelection()
      var selectedText = window.selectedText == undefined ? "" : window.selectedText.toString();
      var wordsCount = selectedText == " " ? 1 : selectedText.split(" ").length;

      if (selectedText != "") {
        
        this.modeBarTarget.querySelector(".text-not-selected").classList.add("hidden")
        this.modeBarTarget.querySelector(".text-selected").classList.remove("hidden")
        this.modeBarTarget.querySelector(".text-selected").innerHTML = `${wordsCount} ${wordsCount == 1 ? "parola selezionata" : "parole selezionate" }`
        this.modalTarget.classList.remove("hidden")
        document.body.classList.remove("select-none")
        // document.body.style.overflowY = "hidden";
        this.selectedTextTarget.value = selectedText
        
        if (this.isMobile) {
          this.confirmButtonTarget.classList.add("hidden")
          this.confirmButtonTarget.setAttribute("disabled", "disabled")
          setTimeout(() => {
            window.getSelection().removeAllRanges();
          }, 100);
        }
      
      } else {

    
          this.modeBarTarget.querySelector(".text-not-selected").classList.remove("hidden")
          this.modeBarTarget.querySelector(".text-selected").classList.add("hidden")
          this.modalTarget.classList.add("hidden")
          if (document.body.classList.contains("r-user")) {
            document.body.classList.add("select-none")
          }

          if (this.isMobile) {
            window.getSelection().removeAllRanges();
          }
  
     
        // document.body.style.overflowY = "auto";
      }
    }

    closeMode() {
      // Close TakeNoteMode
      this.modeBarTarget.classList.remove("active-mode")
      document.querySelectorAll("#article-card p").forEach(el => el.classList.remove("cursor-select-text-questions"))
      window.getSelection().removeAllRanges();
      this.modalTarget.classList.add("hidden")
      if (document.body.classList.contains("r-user")) {
        document.body.classList.add("select-none")
      }
      // document.body.style.overflowY = "auto";
      window.selectedText = undefined
      this.modeBarTarget.querySelector(".text-not-selected").classList.remove("hidden")
      this.modeBarTarget.querySelector(".text-selected").classList.add("hidden")

      if (this.isMobile) {
        this.confirmButtonTarget.classList.add("hidden")
        this.confirmButtonTarget.setAttribute("disabled", "disabled")
      }
    }

}