import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["modal", "content", "button", "confirmButton"]
  
  connect() {
    this.screenWidth = window.screen.width
    this.screenHeight = window.screen.height
    this.isMobile = Math.min(this.screenWidth, this.screenHeight) < 768
  }

  toggleModal() {
    this.modalTarget.classList.toggle("hidden")
  }

  closeModal(e) {
    if (e.target.closest(".modal-article")) return;
    this.modalTarget.classList.add("hidden")
  }

  windowToggleModal() {
    const event = new CustomEvent("toggle-site-feedback-modal")
    window.dispatchEvent(event)
  }

  
  toggleMode() {
    this.modalTarget.classList.toggle("hidden")

  }
}