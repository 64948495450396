import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["textarea", "messagesList"];

  connect() {

    if (this.element.dataset.chatType == "private") this.setMessagesReadStatus()
    
    setTimeout(() => {
      this.messagesListTarget.scrollTop = this.messagesListTarget.scrollHeight + 100;
    }, 0);
    

    document.addEventListener("turbo:before-stream-render", (e) => {
      var appended = e.target
      
      if (appended.target == `kanban_chat_${this.element.dataset.chat}_messages`) {
        setTimeout(() => {
          this.messagesListTarget.scrollTop = this.messagesListTarget.scrollHeight + 100;
        }, 200);

    

        this.markAsRead();
  
      } else {
        // mark_as_read stream

        

        if (this.element.dataset.chatType == "private") {
          this.setMessagesReadStatus(true)  
        }

      }
    })


  }
  
  markAsRead() {
    console.log("mark as read");
    
    fetch("/kanban_chats/" + this.element.chat + "/mark_as_read", {
      method: "post",
      credentials: "same-origin",
      headers: {
        'Accept': "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then((response) => {
      if (response.ok) {
        console.log("OK");
      }
    });
  }


  setMessagesReadStatus(ignoreLastReadAt=false) {
    var messages = this.element.querySelectorAll(".kanban-chat-message");
    if (messages.length == 0) return;
    [...messages].forEach((message) => {
      
      var chatID = message.dataset.chat;

      // receiver user
      var userLastReadAt = new Date(this.element.dataset.userLastReadAt * 1000);

      console.log("last read at", userLastReadAt);

      var messageSentAt = new Date(message.dataset.sentAt * 1000);
      var messageRead = ignoreLastReadAt ? true : (userLastReadAt > messageSentAt)
      
      console.log(messageSentAt);
      console.log(messageRead);
      
      message.querySelector(messageRead ? ".message-read" : ".message-unread").classList.remove("hidden")

    });
  }

  clearTextarea(e) {
    setTimeout(() => {
      this.textareaTarget.value = "";
    }, 0);

    setTimeout(() => {
      this.messagesListTarget.scrollTop = this.messagesListTarget.scrollHeight + 100;
    }, 200);

  }

}