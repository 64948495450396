import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["event", "filterBtn"]

  connect() {
    if (this.hasEventTarget) {
      setTimeout(() => {
        this.eventTarget.classList.remove("animate-pulse")
      }, 2000);
    }
  }
  
  deselectAllFilterCheckboxes(e) {
    e.preventDefault();
    document.querySelectorAll("input[type='checkbox'][name='categories[]']").forEach((el) => el.checked = false);
    this.filterBtnTarget.click();
  }
  
}