import { Controller } from "stimulus";


export default class extends Controller {
  static targets = [];

  connect() {

  }


  selectMessage(e) {
    
    e.preventDefault()
    var replyText = e.target.closest("li").querySelector(".reply-content").textContent
    console.log(replyText)
    var chatTextarea = document.querySelector(".support-chat-modal #new_message #message_content");
    // var submitBtn = document.querySelector(".support-chat-modal #new_message button[type='submit']");
    chatTextarea.value = replyText.trim();
    // submitBtn.click();

    // trigger textarea autogrow
    chatTextarea.dispatchEvent(new Event("input"));


  }

}