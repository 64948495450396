import { Controller } from "stimulus"
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [];

  connect() {

    console.log("connect");

    this.sortable = Sortable.create(this.element, {
      sort: true,
      draggable: ".kanban-calendar-event",
      animation: 100,
      swapThreshold: 0.30,
      easing: "ease-out",
      onEnd: function(evt) {

        let eventsOrder = [...evt.from.querySelectorAll('.kanban-calendar-event')].map((el) => el.dataset.eventId );


        let params = {events_order: eventsOrder}

      

        let path = `/kanbans/${document.querySelector('#kanban').dataset.kanbanId}/kanban_calendars/${document.querySelector('#kanban_calendar').dataset.kanbanCalendarId}/sort-events`;


        fetch(path, {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
            body: JSON.stringify(params)
          }).then(response => {
            if (response.ok) {
              console.log("events position: updated")
            }
          })
      
      }
    })


  }

}