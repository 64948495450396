import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["updateInProgress"]
  
  connect() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const addUserId = urlParams.get('add_user_id');
    if (!!addUserId) {
      var userCard = document.querySelector(`#user_${addUserId}_monitor_card`);
      userCard.classList.add("animate-pulse");
      userCard.scrollIntoView({block: "start", behavior: "smooth"});
      
      setTimeout(() => {
        userCard.classList.remove("animate-pulse");
      }, 3000);
    }
  }

  updateList() {
    this.updateInProgressTarget.classList.toggle("hidden")
  }
}