import { Controller } from "stimulus";
import Rails from 'rails-ujs'

export default class extends Controller {
  
  static targets = ["takeNoteModeBar",
                     "takeNoteWriteArea", 
                     "selectedTextInput", 
                     "saveNoteButton",
                     "writeNoteTextarea",
                     "inlineNotePreview",
                     "noteTag",
                     "deleteNoteButton",
                     "searchNoteForm",
                     "searchNoteInput",
                     "submitSearchNoteFormButton",
                     "cancelSearchButton",
                     "articleTitle",
                     "articleNotesList",
                    "confirmButton"]

  connect() {
    this.screenWidth = window.screen.width
    this.screenHeight = window.screen.height
    this.isMobile = Math.min(this.screenWidth, this.screenHeight) < 768
 
  }


  initialize() {
    document.body.addEventListener("click", el => {
      var classList = el.target.classList
      if (classList.contains("inline-note-preview") || classList.contains("article-note") || classList.contains("delete-note")) return;
      document.querySelectorAll(".inline-note-preview").forEach(el => el.classList.add("hidden"))
    })
  }

  toggleTakeNoteMode() {

    console.log("toggle")

    this.saveNoteButtonTarget.addEventListener("click", e => {
      this.closeTakeNoteMode();
    })
  
    if (this.takeNoteModeBarTarget.classList.contains("active-mode")) {

      this.closeTakeNoteMode()

    } else {
      this.takeNoteModeBarTarget.classList.add("active-mode")
      document.body.classList.remove("select-none")

      document.querySelectorAll("#article-card p").forEach(el => el.classList.add("cursor-select-text"))
       
      
      document.addEventListener("selectionchange", e => {
        if (!document.body.classList.contains("r-user")) return;
        let selection = document.getSelection()
        let selectionWordsCount = selection.toString().split(" ").length
        if (selectionWordsCount == 50) {
          // this.handleSelection(e);
        } else {
          if (selectionWordsCount > 80) {
            if (document.body.classList.contains("r-user")) {
              document.body.classList.add("select-none");
              document.body.classList.remove("select-none");
            }
            document.getSelection().removeAllRanges();
          }
        }
      });

      document.body.addEventListener("mouseup", e => {
        var classList = e.target.classList;
        if (classList.contains("take-note-mode") || classList.contains("take-note-textarea") || classList.contains("take-note-write-area")) return;
        
        if (document.getSelection().toString().split(" ").length <= 80) this.handleSelection(e);
      })

      if (this.isMobile) {
        this.confirmButtonTarget.classList.remove("hidden")
        document.addEventListener("selectionchange", e => {
          if (!document.body.classList.contains("r-user")) return;
          var textSelection = window.getSelection()
          if (textSelection != undefined && textSelection.toString() != "" && textSelection.toString().length > 3) {
        
              this.confirmButtonTarget.removeAttribute("disabled")
          } else {
            this.confirmButtonTarget.setAttribute("disabled", "disabled")
          }
        })
      }


    }
    
  }

  confirmSelection(e) {
    // if (!this.isMobile || window.getSelection() == undefined || window.getSelection().toString().trim() == "") return;
    this.handleSelection(e)
  }

  handleSelection(e) {
    if (!this.takeNoteModeBarTarget.classList.contains("active-mode")) return;

    // var classList = document.querySelector("p.cursor-select-text").classList;

    // if (classList.contains("take-note-mode") || classList.contains("take-note-textarea") || classList.contains("take-note-write-area")) return;
    

    if (window.selectedText == undefined) window.selectedText = window.getSelection()
    var selectedText = window.selectedText == undefined ? "" : window.selectedText.toString();
    var wordsCount = selectedText == " " ? 1 : selectedText.trim().split(" ").length;

    if (selectedText.trim() != "" && selectedText.length > 2) {
      
      this.takeNoteModeBarTarget.querySelector(".text-not-selected").classList.add("hidden")
      this.takeNoteModeBarTarget.querySelector(".text-selected").classList.remove("hidden")
      this.takeNoteModeBarTarget.querySelector(".text-selected").innerHTML = `${wordsCount} ${wordsCount == 1 ? "parola selezionata" : "parole selezionate" }`
      this.takeNoteWriteAreaTarget.classList.remove("hidden")
      document.body.classList.remove("select-none")
      // document.body.style.overflowY = "hidden";
      this.selectedTextInputTarget.value = selectedText

      if (this.isMobile) {
        this.confirmButtonTarget.classList.add("hidden")
        this.confirmButtonTarget.setAttribute("disabled", "disabled")
        setTimeout(() => {
          window.getSelection().removeAllRanges();
        }, 100);
      }
      
    
    } else {
      this.takeNoteModeBarTarget.querySelector(".text-not-selected").classList.remove("hidden")
      this.takeNoteModeBarTarget.querySelector(".text-selected").classList.add("hidden")
      this.takeNoteWriteAreaTarget.classList.add("hidden")
      // document.body.classList.add("select-none")
      // document.body.style.overflowY = "auto";
      // this.closeTakeNoteMode()
      // document.getSelection().removeAllRanges();
      
      if (this.isMobile) {
        window.getSelection().removeAllRanges();
      }

    }
  }


  closeTakeNoteMode() {
    console.log("close")
    // Close TakeNoteMode
    this.takeNoteModeBarTarget.classList.remove("active-mode")
    document.querySelectorAll("#article-card p").forEach(el => el.classList.remove("cursor-select-text"))
    window.getSelection().removeAllRanges();
    this.takeNoteWriteAreaTarget.classList.add("hidden")

    if (document.body.classList.contains("r-user")) {
      document.body.classList.add("select-none")
    }

    // document.body.style.overflowY = "auto";
    window.selectedText = undefined

    if (this.isMobile) {
      this.confirmButtonTarget.classList.add("hidden")
      this.confirmButtonTarget.setAttribute("disabled", "disabled")
    }
  }

  openNotePreview() {
    if (this.inlineNotePreviewTarget.classList.contains("hidden")) {
      document.querySelectorAll(".inline-note-preview").forEach(el => el.classList.add("hidden"))
      this.inlineNotePreviewTarget.classList.remove("hidden")
    } else {
      this.inlineNotePreviewTarget.classList.add("hidden")
    }
  }

  deleteNote() {
    var userId = this.deleteNoteButtonTarget.dataset.userId;
    var noteId = this.deleteNoteButtonTarget.dataset.noteId;

    if (confirm("Sei sicuro?")) {
      fetch(`/users/${userId}/notes/${noteId}`, {
      method: 'delete',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'X-CSRF-Token': Rails.csrfToken(),
      },
      }).then(response => {
        if (response.ok) {
          var text = this.noteTagTarget.innerText
          this.noteTagTarget.parentElement.innerHTML = this.noteTagTarget.innerText
          this.noteTagTarget.remove();
        }
      })
    }
  }

  resetSearch() {
    if (this.searchNoteInputTarget.value == "") {
      this.cancelSearch();
    }
  }

  cancelSearch() {
    this.searchNoteInputTarget.value = ""
    this.submitSearchNoteFormButtonTarget.click()
  }

  toggleArticleNotesList(e) {
    e.preventDefault()
    e.stopPropagation()
    var classList = this.articleNotesListTarget.classList
    if (classList.contains("hidden")) {
      this.articleNotesListTarget.classList.remove("hidden")
      this.articleTitleTarget.classList.add("list-open")
    } else {
      this.articleNotesListTarget.classList.add("hidden")
      this.articleTitleTarget.classList.remove("list-open")
    }
  }

}