import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["uploadModal", "uploadModalContent", "fileUploadList", "loadingSpinner", "coursesModal", "titleInput"]

  connect() {
    document.querySelector(".modal:not(article)").addEventListener('click', (e) => {
      if (e.target.closest("article")) return;
      document.querySelector(".modal").classList.add("hidden")
    })

    // this.mediaUploads = Sortable.create(
    //   document.querySelector(".media-uploads")
    // )
  }

  openUploadModal(event) {
    event.preventDefault();
    this.uploadModalTarget.classList.remove("hidden")
  }

  applyPageNumberStyle(e) {
    e.preventDefault()
    var selectedText = window.getSelection().toString()
    var selectedElementContent = window.editor.model.document.selection.anchor.parent._children._nodes[0]._data

    if (selectedText != "") {
      var content = `<div class='article-page-number'>${selectedElementContent}</div>`
      const viewFragment = editor.data.processor.toView( content );
      const modelFragment = editor.data.toModel( viewFragment );
      window.editor.model.insertContent( modelFragment, editor.model.document.selection );
    }
  }

  stickFileUploadList() {
    this.fileUploadListTarget.classList.toggle("sticky-upload-list")
  }

  showLoadingSpinner() {
    if (this.titleInputTarget.value != "") {
      this.loadingSpinnerTarget.classList.remove("hidden")
    }
  }

  deselectOtherCheckboxes(e) {
    console.log(e)
  }

  openCoursesModal(e) {
    e.preventDefault()
    this.coursesModalTarget.classList.remove("hidden")
  }

  selectCourses(e) {
    e.preventDefault()
    this.coursesModalTarget.classList.add("hidden")
  }


}