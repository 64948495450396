import { Controller } from "stimulus"
import Sortable from 'sortablejs'
import Rails from '@rails/ujs'

export default class extends Controller {
  
  static targets = ["dropdownToggle", "dropdown"];

  connect() {

    var isTouchDevice = (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));    

    this.sortable = Sortable.create(this.element, {
      group: "columns",
      sort: true,
      draggable: ".kanban-card",
      animation: 100,
      disabled: isTouchDevice,
      ghostClass: 'ghost-kanban-card',
      swapThreshold: 0.30,
      easing: "ease-out",
      onEnd: function(evt) {
        let sourceCardsOrder = [...evt.from.querySelectorAll('.kanban-card')].map((el) => el.dataset.kanbanCardId );
        let targetCardsOrder = [...evt.to.querySelectorAll('.kanban-card')].map((el) => el.dataset.kanbanCardId );
        let fromColId = evt.from.dataset.kanbanColumnId;
        let toColId = evt.to.dataset.kanbanColumnId;

        let params = {source_col_id: fromColId, target_col_id: toColId, source_col_order: sourceCardsOrder, target_col_order: targetCardsOrder}

        fetch('/kanbans/' + document.querySelector('#kanban').dataset.kanbanId + '/sort-cards', {
          method: 'PUT',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
            body: JSON.stringify(params)
          }).then(response => {
            if (response.ok) {
              console.log("position: updated")
            }
          })
      
      }
    })
    
    if (window.sortableCards == undefined) {
      window.sortableCards = [];
    }

    window.sortableCards.push(this.sortable);

  }

  toggleDropdown(e) {
    this.dropdownTarget.classList.toggle("hidden");
  }

  // toggleCardsSortable() {
  //   window.sortableCards.forEach((sortable) => { sortable.option("disabled", !sortable.options.disabled) });
  // }

}