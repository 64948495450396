import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["textarea", "submitBtn", "spinner", "surveyArea", "toggleSurveyBtn", "notesField"];

  connect() {
    document.addEventListener("turbo:before-stream-render", (e) => {
      var appended = e.target
      if (appended.target == `survey_found_acd_by`) {

        setTimeout(() => {
          this.element.remove();
        }, 3500);

      }
    })

  }

  submitSurvey(e) {
    // this.submitBtnTarget.disabled = true;
    this.submitBtnTarget.classList.add("hidden");
    this.spinnerTarget.classList.remove("hidden");
  }

  toggleTextarea(e) {
    if (["social", "altro"].includes(e.target.value)) {
      this.textareaTarget.required = true;
      this.textareaTarget.classList.remove("hidden");
      if (e.target.value == "social") {
        this.textareaTarget.placeholder = "Quale social?"
      } else {
        this.textareaTarget.placeholder = "Inserisci più dettagli"
      }
    } else {
      this.textareaTarget.required = false;
      this.textareaTarget.classList.add("hidden");
      this.textareaTarget.placeholder = "Inserisci più dettagli"
    }
  }

  toggleSurvey(e) {
    this.surveyAreaTarget.classList.toggle("hidden");
    this.toggleSurveyBtnTarget.classList.toggle("hidden");
  }

  showNotesField(e) {
   
      let val = e.target.value;
      let id = e.target.dataset.questionId;
      
      if (!!document.querySelector(`#question_${id}_field_other`)) {
        if (val == "Altro") {
          document.querySelector(`#question_${id}_field_other`).classList.remove("hidden");
          document.querySelector(`#question_${id}_field_other textarea`).required = true;
        } else {
          document.querySelector(`#question_${id}_field_other`).classList.add("hidden");
          document.querySelector(`#question_${id}_field_other textarea`).required = false;
        }
      }
    
  }

}