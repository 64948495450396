import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["static", "animated"];

  connect() {
    setInterval(() => {
      this.changeLogo();
    }, 10000);

  }

  changeLogo() {
    var date = new Date();
    if (date.getMinutes() % 10 == 0) {
      this.staticTarget.classList.add("hidden");
      this.animatedTarget.classList.remove("hidden");
    } else {
      this.animatedTarget.classList.add("hidden");
      this.staticTarget.classList.remove("hidden");
    }
  }

}