import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["search", "utilsContainer"]

  connect() {
    if (document.querySelector("#loading_spinner")) {
      document.querySelector("#loading_spinner").classList.add("hidden")
    }
    
    document.addEventListener("click", (e) => {
      if (!e.target.closest("li.article-li")) return;
      if (!e.shiftKey) return;
      e.preventDefault()
      if (e.target.closest("li").classList.contains("onhold")) {
        e.target.closest("li").classList.remove("onhold")
      }
      
      document.querySelectorAll(".onhold").forEach((el) => el.classList.remove("onhold"))
      e.target.closest("li").classList.add("onhold")
    })

    document.addEventListener("dragstart", (e) => {
      if (!e.target.classList.contains("onhold")) {
        if (e.shiftKey) {
          if (!!document.querySelector(".onhold")) document.querySelector(".onhold").classList.remove("onhold")
          // e.target.closest("li").classList.add("onhold")
          // e.preventDefault()
        }
      } else {
        e.target.closest("li").classList.remove("onhold")
      }
    })

    document.addEventListener("dragend", (e) => {
      if (!!document.querySelector("#loading_spinner")) {
        document.querySelector("#loading_spinner").classList.remove("hidden")
      }
    })

  }

  jumpToStep(e) {
    e.preventDefault()
    var step = e.target.dataset.step
    var el = document.querySelector(`.step-${step}.step-first-article`)
    el.scrollIntoView()
  }

  scrollSearch(e) {
    if (e.keyCode === 13) {
      var val = this.searchTarget.value
      document.querySelector("#sortable_steps_articles_list").focus()
      window.find(val)
    }
  }
  
  toggleUtils(e) {
    if (this.hasUtilsContainerTarget) {
      this.utilsContainerTarget.classList.toggle("hidden")
    }
  }

}