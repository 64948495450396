import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["closeBtn"];


  connect() {
    
    document.addEventListener('click', (e) => {   
      if (this.element.contains(e.target)){
      } else{
        this.element.closest("turbo-frame").src = ""
        this.close()
      }
    });

  }


  close() {
    this.element.classList.add("hidden");
  }


}