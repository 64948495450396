import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {

  connect() {
    this.targetElements = ".article-badges, .step-percentages, .article-info"
  }

  get currentUserID() {
    return document.body.dataset.userId
  }

  simple() {
    document.querySelectorAll(this.targetElements).forEach((el) => {
      el.classList.add("invisible");
    });
    this.saveMode("simple")
  }

  advanced() {
    document.querySelectorAll(this.targetElements).forEach((el) => {
      el.classList.remove("invisible");
    });
    this.saveMode("advanced")
  }

  saveMode(visualMode) {
    fetch(`/users/${this.currentUserID}/save-visual-mode`, {
      method: "post",
      credentials: "same-origin",
      headers: {
        'Accept': "application/json, text/plain, */*",
        "Content-Type": "application/json",
        'X-CSRF-Token': Rails.csrfToken(),
      },
      body: JSON.stringify({
        visualMode: visualMode
      }),
    }).then((response) => {
 
    });
  }
}
