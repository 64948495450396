import { Controller } from "stimulus";


export default class extends Controller {

  static targets = ["image", "modal", "modalimg"];

  connect() {
    console.log("conn2");
  }


  open() {
    this.modalTarget.classList.remove("hidden");
    var originalURL = this.imageTarget.dataset.originalImage;
    this.modalimgTarget.style.backgroundImage = `url(${originalURL})`;
  }

  close(e) {
    e.preventDefault();
    this.modalTarget.classList.add("hidden");
  }

  bgClose(e) {
    if (e.target == this.modalimgTarget) return;
    this.modalTarget.classList.add("hidden");
  }

}
