import { Controller } from "stimulus";

export default class extends Controller {
  
  static targets = ["chevron", "data", "articlesVisits", "pagesVisits"]

  toggle() {
    this.element.classList.toggle('-translate-x-96')
    // if (this.hasDataTarget) this.dataTarget.classList.toggle('hidden')
    // this.chevronTarget.classList.toggle('-rotate-180')
    // if (this.element.classList.contains('h-2/6')) this.element.classList.toggle('h-2/6')
    // if (this.element.classList.contains('h-1/6')) this.element.classList.toggle('h-1/6')
    // this.element.classList.toggle('h-5')
  }

  switchList() {
    this.articlesVisitsTarget.classList.toggle('hidden')
    this.pagesVisitsTarget.classList.toggle('hidden')
  }


}