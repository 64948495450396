import { Controller } from 'stimulus';
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["initBtn", "list", "saveBtn", "bulletListTitle", "savedText"]

  connect() {
    console.log("connected 2")
  }

  addBulletPoint(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      const clone = document.querySelector("#bulletPoint").content.cloneNode(true);
      var child = this.listTarget.appendChild(clone);
      document.querySelector("#bulletList .bullet-point:last-child > input").focus();
    } else if (e.key === "Backspace") {
      if (e.target.value == "") {
        if (document.querySelectorAll(".bullet-point").length > 1) {
          let prev = e.target.parentElement.previousElementSibling;
          console.log(prev);
          e.target.parentElement.remove();
          prev.querySelector("input:first-child").focus();
        }
      }
    }
  }

  initBulletList(e) {
    e.preventDefault();
    this.initBtnTarget.remove();
    const clone = document.querySelector("#bulletPoint").content.cloneNode(true);
    var child = this.listTarget.appendChild(clone);
    document.querySelector("#bulletList .bullet-point:last-child > input").focus();
    this.saveBtnTarget.classList.remove("hidden");
    this.bulletListTitleTarget.classList.remove("hidden");
  }

  saveBulletList(e) {
    e.preventDefault();

    var kanbanID = e.target.dataset.kanbanId;

    var kanbanColumnID = e.target.dataset.kanbanColumnId;

    var kanbanCardID = e.target.dataset.kanbanCardId;

    var data = { bullets: {} };

    document.querySelectorAll(".bullet-point").forEach(function(bulletPoint, index) { 
      let content = bulletPoint.querySelector(".bullet-point-input").value;
      let checked = bulletPoint.querySelector(".bullet-point-checkbox").checked;
      data["bullets"][index] = { "content": content, "checked": checked };
    });

    console.log(data);

    fetch(`/kanbans/${kanbanID}/kanban_columns/${kanbanColumnID}/kanban_cards/${kanbanCardID}/bullet_list`, {
      method: "post",
      credentials: "same-origin",
      headers: {
        'Accept': "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        
        this.savedTextTarget.classList.remove("hidden");

        setTimeout(() => {
          this.savedTextTarget.classList.add("hidden");
        }, 2000);
      }
    });


  }

}