import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["modal"];

  connect() {
    // document.querySelector("body").classList.add("scroll-disabled")
  }

  disconnect() {
    document.querySelector("body").classList.remove("scroll-disabled")
  }

  open(e) {
    e.preventDefault();
    this.modalTarget.classList.remove("hidden");
  }

  toggle(event) {
    if (event.target.classList.contains("is-hidden")) {
      event.target.classList.remove("is-hidden")
    } else {
      event.target.classList.add("is-hidden")
    }
  }

  closeEditProfileModal(event) {
    if (!event.target.closest("article")) {
      event.target.remove()
      document.querySelector("turbo-frame#edit_profile_modal").removeAttribute("src")
    }
  }

  closeChatsModal(event) {
    if (event.target.classList.contains("modal-window")) {
      this.element.remove()
      document.querySelector("turbo-frame#chats_list").removeAttribute("src")
    }
  }

  
  // old
  close(event) {
    // console.log("close")
    if (event.target == document.getElementById('modal-window') || event.target.classList.contains("modal-window") || event.target.classList.contains("modal-close") || event.target.classList.contains("fe-close") || event.target.classList.contains("modal-cancel")) {
      this.element.remove()
      document.getElementById("permissions-modal").removeAttribute('src')
      document.getElementById("permission-groups-modal").removeAttribute('src')
    }
    
  }


  softClose(event) {
    if (event.target == document.getElementById('modal-window') || event.target.classList.contains("modal-window") || event.target.classList.contains("modal-close") || event.target.classList.contains("fe-close") || event.target.classList.contains("modal-cancel")) {
      document.querySelector(".modal-window").classList.add("hidden");
    }
  }

  escClose(event) {
    if (event.key === 'Escape') this.close()
  }


  hide(e) {
    if (e.target.classList.contains("modal-window")) {
      if (this.hasModalTarget) {
       this.modalTarget.classList.add("hidden");
      } else {
        
        
        if (this.element.classList.contains("kanban-card-modal")) {
          this.element.querySelector(".submit-btn-container button").click();
        }

        this.element.classList.add("hidden");

      }
    }
  }

  hideClosest(e) {
    e.target.closest(".modal-window").classList.add("hidden");
  }

  hideAll(e) {
    document.querySelectorAll(".modal-window").forEach((modal) => {
      modal.classList.add("hidden");
    })
  }


  softHideAll(e) {
      
      e.preventDefault();
      document.querySelectorAll(".modal-window").forEach((modal) => {
        modal.classList.add("hidden");
      })
    
  }

  turboClose(e) {
    // hide modal and force turbo-frame refresh removing src attribute
    
    this.hide(e)
    var parent = this.element.parentElement
    if (parent.tagName == "TURBO-FRAME") {
      parent.removeAttribute("src")
    }
  }

  openTargetModal(e) {
    e.preventDefault()
    var modal = document.querySelector(`#${e.target.dataset.targetModal}`);
    if (!!modal) modal.classList.remove("hidden");
  }


  statsOpenTargetModal(e) {
    e.preventDefault()
    if (e.target.tagName == "A") return;
    var modal = document.querySelector(`#${e.target.dataset.targetModal}`);
    if (!!modal) modal.classList.remove("hidden");
  }

}