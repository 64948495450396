import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["diaryPageModal", 
                    "diaryPage",
                    "title",
                    "content",
                    "saveDiaryPageButton", 
                    "diaryPageModalFrame", 
                    "editButton", 
                    "commentsListWrap",
                    "deleteButton"]

  get createdAt() {
    if (this.hasDiaryPageTarget) {
      return new Date(parseFloat(this.diaryPageTarget.dataset.createdAt))
    }
  }

  get timeElapsed() {
    return new Date() - this.createdAt
  }

  get minutesElapsed() {
    return Math.floor(this.timeElapsed / 60000)
  }

  get hoursElapsed() {
    return Math.floor(this.minutesElapsed / 60)
  }

  connect() {
    if (this.hoursElapsed < 1) {
      this.editButtonTarget.classList.remove("hidden")
    }

    if (this.hoursElapsed < 24) {
      this.deleteButtonTarget.classList.remove("hidden")
    }
  }

  initialize() {
      document.body.addEventListener("click", e => {
        if (!e.target.closest(".write-diary-page-modal article") && !e.target.closest(".context-menu-container")) {
          if (document.querySelector(".write-diary-page-modal") != null) document.querySelector(".write-diary-page-modal").classList.add("hidden")
        }
      })
  }

  toggleDiaryPageModal() {
    var classList = this.hasDiaryPageModalFrameTarget ? this.diaryPageModalFrameTarget.classList : this.diaryPageModalTarget.classList;
    classList.contains("hidden") ? classList.remove("hidden") : classList.add("hidden")
  }

  closeDiaryPageModal() {
    this.diaryPageModalTarget.classList.add("hidden")
  }

  toggleContent(e) {
    e.preventDefault()
    this.contentTarget.classList.toggle("hidden")
    this.titleTarget.classList.toggle("show-content")
  }

  toggleComments() {
    if (this.commentsListWrapTarget.querySelector(".comments-list") == null) return;
    this.hasCommentsListWrapTarget
    this.commentsListWrapTarget.classList.toggle("is-hidden")
  }

}