import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["toggle", "dropdown"];

  connect() {
    console.log("connected dropdown")  
  }

  toggle(e) {
    e.preventDefault()
    this.dropdownTarget.classList.toggle("hidden");
  }

}