import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {

  static targets = ["toCloneQuestion", "toClone", "choicesFields", "questionArea", "editFormContainer", "resultsList", "questionsDataField"];

  connect() {
    
    console.log("connect");

    if (!!document.querySelector("#questions_list")) {
      window.sortable = Sortable.create(document.querySelector("#questions_list"), {
        group: "surveylist",
        sort: true,
        draggable: ".question-item",
        handle: ".question-item-draggable",
        onChoose: evt => {  },
        onMove: evt => {  },
        onEnd: evt => {

          this.updateQuestionPositions();

         }
      })
    }


    if (!!document.querySelector("#choicesFields")) {
      window.sortable = Sortable.create(document.querySelector("#choicesFields"), {
        group: "name",
        sort: true,
        draggable: ".parent-field",
        handle: ".parent-field-draggable",
        onChoose: evt => {  },
        onMove: evt => {  },
        onEnd: evt => { }
      })
    }

    
  }


  updateQuestionPositions() {
    var positions = [...document.querySelectorAll(".question-item")].map((el, i) => {
      let id = el.dataset.questionId;
      return {id: id, position: i+1};
    });

    document.querySelector("#questions_positions").value = JSON.stringify(positions);
    console.log("positions updated.")
  }



  addChoiceField(e) {
    e.preventDefault();
    let newField = this.toCloneTarget.cloneNode(true);
    newField.removeAttribute("data-survey-target");
    newField.classList.remove("hidden");
    this.choicesFieldsTarget.appendChild(newField);
  }

  deleteChoiceField(e) {
    e.preventDefault();
    e.target.closest(".parent-field").remove();
  }

  deleteQuestion(e) {
    e.preventDefault();
    if (confirm("Rimuovere dalla lista? Clicca su Salva per confermare")) {
      e.target.closest(".question-item").remove();
      this.updateQuestionPositions();
    }
  }

  showEditForm(e) {
    e.preventDefault();
    this.editFormContainerTarget.classList.toggle("hidden");
    this.resultsListTarget.classList.toggle("hidden");
  }

  copyCode(e) {
    e.preventDefault();
    let text = e.target.textContent;
    this.copyRichText(text);
  }

  
  copyRichText(text) {
    const listener = function(ev) {
      ev.preventDefault();
      ev.clipboardData.setData('text/plain', text);
      ev.clipboardData.setData('text/html', text);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }



}