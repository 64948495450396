import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["btn", "selector"]

  connect() {
    // document.querySelectorAll(".scroll-to-step-link").forEach((el) => {
    //   el.addEventListener("click", (e) => {
    //     this.selectorTarget.classList.add("hidden")
    //   })
    // })
  }

  toggle() {
    this.selectorTarget.classList.toggle("hidden")
  }

  scroll() {
    this.selectorTarget.classList.add("hidden")
  }

  scrollToStep(e) {
    e.preventDefault()
    var step = e.target.dataset.step;
    setTimeout(() => {
      document.querySelector(".step-selector").classList.add("hidden")
    });
    document.querySelector(`#step-${step}`).scrollIntoView()
  }

}