import Rails from "rails-ujs";
import devtools from "devtools-detect";


function track(data) {
  fetch("/analytics/track", {
    method: "post",
    credentials: "same-origin",
    headers: {
      'Accept': "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    if (response.ok) {
      
    }
  });
}

// function xpath(el) {
//   if (typeof el == "string") return document.evaluate(el, document, null, 0, null)
//   if (!el || el.nodeType != 1) return ''
//   if (el.id) return "//*[@id='" + el.id + "']"
//   var sames = [].filter.call(el.parentNode.children, function (x) { return x.tagName == el.tagName })
//   return xpath(el.parentNode) + '/' + el.tagName.toLowerCase() + (sames.length > 1 ? '['+([].indexOf.call(sames, el)+1)+']' : '')
// }


// document.addEventListener("mousemove", function(event) {
//     if (!["BODY", "HTML"].includes(event.target.tagName)) {
//       console.log(event.target.outerHTML);
//     }
// });


var lastDevtoolsOpen = 0;
var isMobile = (/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(navigator.userAgent) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(navigator.userAgent)); 
if (!isMobile) {
  window.addEventListener('devtoolschange', event => {

    if (document.querySelector(".development-mode") == null) {
      if (!event.target.location.hostname.includes("accademiadicoscienzadimensionale")) return;
    }

    if (!document.body.classList.contains("user-logged-in")) return;
    
    if (event.detail.isOpen) {
      var now = new Date();
      if (now - lastDevtoolsOpen >= 4000) {
        track({event_type: "devtools_open"});
        lastDevtoolsOpen = now;
      }
    }
  });
}


var lastKeypressTrack = 0;
document.addEventListener("keydown", function(e) {

  if (!document.body.classList.contains("user-logged-in")) return;
  
  if ( (e.metaKey || e.ctrlKey) && ["c", "v", "x", "f"].includes(e.key)) {
    var commands = { "c": "copy", "v": "paste", "x": "cut", "f": "search" };
    var now = new Date();
    if (now - lastKeypressTrack >= 4000) {
      track({event_type: "keypress", command: commands[e.key]});
      lastKeypressTrack = now;
    }
  }
});


function checkVisible(elm, threshold, mode) {
  threshold = threshold || 0;
  mode = mode || 'visible';

  var rect = elm.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  var above = rect.bottom - threshold < 0;
  var below = rect.top - viewHeight + threshold >= 0;

  return mode === 'above' ? above : (mode === 'below' ? below : !above && !below);
}

window.checkVisibleArticles = function() {
  var currentlyLoadedArticles = document.querySelectorAll(".articles.index .step-block .article-li");
  
  var visibleArticles = [...currentlyLoadedArticles].map(function(article) {
    if (checkVisible(article, 1)) return article.dataset.articleId;
  }).filter(x => x !== undefined);

  return visibleArticles;
}


document.addEventListener("DOMContentLoaded", () => {

  if (!!document.querySelector(".articles.index")) {
    var checked = false;
    
    setTimeout(() => {
      var articlesIDs = window.checkVisibleArticles();
      track({event_type: "home_inactive", last_seen_articles: articlesIDs})
    }, 300000);
  
  }


  if (!!document.body.dataset.userId) {
    var localLogins = localStorage.getItem("localLogins");
    var currentLogin = document.body.dataset.userId;
  
    // localLogins = localLogins == null ? [currentLogin] : JSON.parse(localLogins).push(currentLogin);

   
  
    if (localLogins == null) {
      localLogins = [currentLogin]
    } else {
      let arr = JSON.parse(localLogins)
      arr.push(currentLogin);
      localLogins = arr;
    }
    
    localLogins = localLogins.filter((v,i,a)=>a.indexOf(v)==i);
    localStorage.setItem("localLogins", JSON.stringify(localLogins));

    track({event_type: "local_logins", local_logins: localLogins, current_login: currentLogin});
    
  } else {
    if (location.pathname == "/auth/login" && location.href.includes("failed=true")) {
      // localstorage

      console.log("local logins", localLogins);
    
    }
  }



});