import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["input", "showButton", "hideButton"]

  toggle() {
    this.showButtonTarget.classList.toggle("hidden")
    this.hideButtonTarget.classList.toggle("hidden")
    var inputType = this.inputTarget.getAttribute("type")
    this.inputTarget.setAttribute("type", inputType == "password" ? "text" : "password")
  }

}