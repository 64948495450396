import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["ordersList"];

  connect() {

  }

  copyOrders(e) {
    var list = Array.from(this.ordersListTarget.querySelectorAll(".order")).map(order => {
      console.log(order);

      var id = order.querySelector(".order-id");
      var date = order.querySelector(".order-date");
      var username = order.querySelector(".order-username");
      var content = order.querySelector(".order-choices");

      return `<small>Ordine ${id.innerHTML} - ${date.innerHTML}</small><br><br>${username.innerHTML}:<br><br><br>${content.innerHTML}`
      // console.log(username.innerText)
      // console.log(content.innerText)
    });

    

    this.copyRichText(list.join("<br><br><hr><br><br>"));
  }


  copyRichText(text) {
    const listener = function(ev) {
      ev.preventDefault();
      ev.clipboardData.setData('text/plain', text);
      ev.clipboardData.setData('text/html', text);
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

}