import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.localStorageKey = `support_chat_${this.inputTarget.dataset.chat}`;
    this.restoreAutosavedInputValue();
  }

  getInputData() {
    return  {[this.localStorageKey]: this.inputTarget.value};
  }

  saveToLocalStorage() {
    const data = this.getInputData();
    localStorage.setItem(this.localStorageKey, JSON.stringify(data))
  }


  restoreAutosavedInputValue() {
    if (localStorage.getItem(this.localStorageKey) == null) return;
    const inputData = JSON.parse(localStorage.getItem(this.localStorageKey));
    this.inputTarget.value = inputData[this.localStorageKey];

  }

  clearLocalStorage() {
    if (localStorage.getItem(this.localStorageKey == null)) return;
    setTimeout(() => {
      localStorage.removeItem(this.localStorageKey);
      this.inputTarget.value = ""
      this.inputTarget.focus()
    }, 0);
  }

}