import { Controller } from "stimulus";
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ["chevron", "turboFrame", "toggleStepsBtn"]
  
  connect() {
    this.calcPercentages()
  }

  toggleStep() {
    this.chevronTarget.classList.toggle("rotate-180")
    this.turboFrameTarget.classList.toggle("hidden")
    this.saveStepsStatus()
  }


  toggleSteps(e) {
    e.preventDefault();

    this.toggleStepsBtnTarget.querySelector(".arrow-btn").classList.toggle("rotate-180")

    if (this.toggleStepsBtnTarget.classList.contains("steps-closed")) {
      this.openAll(e)
      this.toggleStepsBtnTarget.classList.remove("steps-closed")
    } else {
      this.toggleStepsBtnTarget.classList.add("steps-closed")
      this.hideAll(e)
    }
  }

  openAll(e) {
    e.preventDefault()
    this.saveStepsStatus()

    if (document.querySelector('.open-all-steps').dataset.loaded == "false") {
      document.querySelector('.open-all-steps').setAttribute("data-loaded", "true")
      document.querySelector(".step-toggle[data-step='1'] > a").click()

      setTimeout(() => {
        document.querySelectorAll(".step-toggle:not([data-step='1']) > a").forEach(el => { el.click() })
      }, 200);

    } else {
      document.querySelector(".step-toggle[data-step='1'] turbo-frame").classList.remove("hidden")
      document.querySelector(".step-toggle").querySelector(".chevron").classList.add("rotate-180")
      
      setTimeout(() => {
        // delay for performance
        document.querySelectorAll(".step-toggle:not([data-step='1']) turbo-frame").forEach(el => { el.classList.remove("hidden") })
      }, 10);

    }
  }

  hideAll(e) {
    e.preventDefault()
    document.querySelectorAll(".step-toggle").forEach(el => { 
      el.querySelector("turbo-frame").classList.add("hidden")
      el.querySelector(".chevron").classList.remove("rotate-180")
    })
    this.saveStepsStatus()
  }

  calcPercentages() {

    document.querySelectorAll(".step-block").forEach(stepBlock => {      
      var stepArticles = stepBlock.querySelectorAll('.article') 
      if (stepArticles.length == 0) return;
      var readArticles = stepBlock.querySelectorAll('.article[data-percentable="true"]').length
      var commentedArticles = stepBlock.querySelectorAll('.article[data-commented="true"]').length
      var readPercentage = Math.round((readArticles / stepArticles.length) * 100)
      var commentedPercentage = Math.round((commentedArticles / stepArticles.length) * 100)
      if (stepBlock.querySelector(".step-percentage-articles") != null) {
        stepBlock.querySelector(".step-percentage-articles").innerHTML = `${readPercentage}%`
        stepBlock.querySelector(".step-percentage-comments").innerHTML = `${commentedPercentage}%`
      }

    })

  }

  get currentUserID() {
    return document.body.dataset.userId
  }

  saveStepsStatus() {
    if (!document.body.classList.contains("user-logged-in")) return;
    fetch(`/users/${this.currentUserID}/settings/steps`, {
      method: "put",
      credentials: "same-origin",
      headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: JSON.stringify(
        {
          closed_steps: Array.from(document.querySelectorAll(".step-toggle turbo-frame.hidden")).map(el => parseInt(el.dataset.step)),
          open_steps: Array.from(document.querySelectorAll(".step-toggle turbo-frame:not(.hidden)")).map(el => parseInt(el.dataset.step)),
        } 
      )
    })
  }

}