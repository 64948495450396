import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    this.element.setAttribute("style", "height:" + (this.element.scrollHeight) + "px;");
    this.element.addEventListener("input", function() {
      this.style.height = "40px";
      this.style.height = (this.scrollHeight) + "px";
    }, false);
  }

}