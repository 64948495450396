import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["arrowLeft", "arrowRight", "imageContainer"]

  connect() {
    this.element.addEventListener('click', (e) => {
      if (e.target.closest(".modal-content")) return;
      this.element.remove()
      document.querySelector("turbo-frame#article_gallery").removeAttribute("src")
    })

    this.arrowLeftTarget.addEventListener('click', (e) => {
      document.querySelector("turbo-frame#article_gallery").removeAttribute("src")
    })


    this.arrowRightTarget.addEventListener('click', (e) => {
      document.querySelector("turbo-frame#article_gallery").removeAttribute("src")
    })

    this.blobId = this.element.dataset.blobId;
    this.setupNavigation()
  }

  setupNavigation() {
    const images = document.querySelectorAll(".gallery-block img")
    const imagesArray = Array.from(images)
    this.currentIndex = imagesArray.findIndex(x => x.src.includes(this.blobId))

    var prevElement = imagesArray[this.currentIndex-1]
    var nextElement = imagesArray[this.currentIndex+1]
    
    if (prevElement == undefined) { 
      this.arrowLeftTarget.classList.add("invisible") 
    } else {
      this.arrowLeftTarget.classList.remove("invisible")
      var src = prevElement.src.split("/")
      this.arrowLeftTarget.href = `/attachments/${src[src.length-2]}`
    }


    if (nextElement == undefined) {
      this.arrowRightTarget.classList.add("invisible")
    } else {
      this.arrowRightTarget.classList.remove("invisible")
      var src = nextElement.src.split("/")
      this.arrowRightTarget.href = `/attachments/${src[src.length-2]}`
    }

    // if (imagesArray.length > 1) {
    //   if (this.currentIndex == 0) {
    //     this.arrowRightTarget.href = imagesArray[this.currentIndex+1].src

    //     this.arrowLeftTarget.classList.add("invisible")
    //     this.arrowRightTarget.classList.remove("invisible")
    //   } else if ( this.currentIndex > 0 ) {
    //     this.arrowLeftTarget.href = imagesArray[this.currentIndex-1].src


    //   }
    // }
 

    // images.forEach(el => {
    //   const src = el.src.split("/")
    //   const blobId = src[src.length-2]
    //   if (blobId)
    // })
  }

  toggleFullscreen() {
    this.imageContainerTarget.classList.toggle("fullscreen-image")
    document.body.classList.toggle("overflow-hidden")
  }

}