import { Controller } from 'stimulus';
import Rails from 'rails-ujs'

export default class extends Controller {
  connect() {
    if (document.querySelector('body').classList.contains("courses") && this.element.dataset.redirect == "true") {
      setTimeout(() => {
        fetch(`/articles/${this.element.dataset.articleId}/sync_hathor_comments_count`, {
          method: 'post',
          credentials: 'same-origin',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
          body: JSON.stringify(
            {
              course_id: document.querySelector('.course-page').dataset.courseId,
            }
          )
        })
      }, 0);
   
    }
  }
}