import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["sideMenu", "selectCategoryNotice"]

  connect() {
    this.sideMenuTarget.classList.remove("hidden");
  }

  toggle(e) {
    e.preventDefault();
    this.sideMenuTarget.classList.toggle("-translate-x-full");
  }


}