import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["input", "submit"]

  reset() {
    if (this.inputTarget.value.length == 0) {
      this.submitTarget.click()
    }
  }
  
      
}