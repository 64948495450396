import { Controller } from "stimulus";
import Rails from "rails-ujs";
import { useIdle } from "stimulus-use";
import Plyr from "plyr";

export default class extends Controller {
  static targets = ["articleCard"];

  connect() {
    this.articleNumber = this.articleCardTarget.dataset.articleNumber;
    this.articleCategory = this.articleCardTarget.dataset.articleCategory;
    this.fromBook = this.articleCardTarget.dataset.fromBook == "true";
    useIdle(this, { ms: "180000", initialState: false });
    // this.setKeyboardNavigation();
    this.scrollToNote();
    this.scrollToComment();
    this.highlightFeedbackSelectedText();
    this.highlightQuestionSelectedText();
    document
      .querySelectorAll(".ck.ck-reset_all.ck-widget__type-around")
      .forEach((el) => {
        el.remove();
      });
    document.querySelectorAll(".gallery-block-container").forEach((el) => {
      el.classList.remove(
        "ck-widget",
        "ck-widget_with-resizer",
        "ck-widget_selected"
      );
      el.removeAttribute("contenteditable");
    });
    
    this.articleId = this.articleCardTarget.dataset.articleId;
    this.bypassReadCheck = this.articleCardTarget.dataset.bypassReadCheck;
    this.readCompletedNotified = false;
    this.completedAudio = false;
    this.scrollReachedEnd = false;

    if (this.bypassReadCheck == "true") {
      document.addEventListener("scroll", (event) => {
        if (this.isInViewport(document.querySelector("#copyright_notice"))) {
          if (!this.scrollReachedEnd) {
            this.scrollReachedEnd = true;
          }
        }
      });
    }

    this.players = Plyr.setup(".acd-media-player", {
       storage: false,
       autopause: true,
       disableContextMenu: true,
       tooltips: { controls: true, seek: true },
      controls: [
        "play-large", // The large play button in the center
        "restart", // Restart playback
        "rewind", // Rewind by the seek time (default 10 seconds)
        "play", // Play/pause playback
        "fast-forward", // Fast forward by the seek time (default 10 seconds)
        "progress", // The progress bar and scrubber for playback and buffering
        "current-time", // The current time of playback
        "duration", // The full duration of the media
        "mute", // Toggle mute
        "volume", // Volume control
        "settings", // Settings menu
        "fullscreen", // Toggle fullscreen
      ],
      i18n: {
        restart: "Riavvia",
        rewind: "Indietro di {seektime}s",
        play: "Play",
        pause: "Pausa",
        fastForward: "Avanti di {seektime}s",
        seek: "Seek",
        seekLabel: "{currentTime} of {duration}",
        played: "Riprodotto",
        buffered: "Buffered",
        currentTime: "Minuto attuale",
        duration: "Durata",
        volume: "Volume",
        mute: "Silenzia",
        unmute: "Attiva audio",
        enableCaptions: "Attiva sottotitoli",
        disableCaptions: "Disattiva sottotitoli",
        download: "Download",
        enterFullscreen: "Guarda in schermo intero",
        exitFullscreen: "Esci da schermo intero",
        frameTitle: "ACD Player - {title}",
        captions: "Sottotitoli",
        settings: "Impostazioni",
        pip: "PIP",
        menuBack: "Vai al menu precedente",
        speed: "Velocità",
        normal: "Normale",
        quality: "Qualità",
        loop: "Loop",
        start: "Inizio",
        end: "Fine",
        all: "Tutto",
        reset: "Reset",
        disabled: "Disabilitato",
        enabled: "Abilitato",
        qualityBadge: {
          2160: "4K",
          1440: "HD",
          1080: "HD",
          720: "HD",
          576: "SD",
          480: "SD",
        },
      },
    });

    // this.players.forEach((player) => {
      
    //     player.media.preload = 'none';

    //     player.media.addEventListener("error", (event) => {





          // console.error("Player error");
          // var originalCurrentTime = player.currentTime;
          // var originalSource = player.source;

          // console.log("current time", player.currentTime);

          // // console.log("target error", event.target.error);

          // // console.log("origin source",originalSource);
          
          // player.source = {
          //   type: "audio",
          //   title: "Audio",
          //   sources: [
          //     {src: originalSource, type: 'audio/mpeg'}
          //   ],
          // };

    
          
          // setTimeout(() => {
          //   player.play().then(() => {
          //     console.log("after play");
          //     // player.currentTime = originalCurrentTime;
          //   });
          // }, 500);
  
  
          // console.log(player.currentTime);
    //     });
      

    // });

    if (!document.body.classList.contains("r-admin") && this.bypassReadCheck == "false") {
      clearInterval(this.readStartTimer);
      this.startActivityTracker(this.articleCardTarget);
    }

    document.querySelectorAll("#article_card_content a[href*=\\#]").forEach((a) => {
      if(a.pathname == window.location.pathname) a.target="_top";
    });

  }

  disconnect() {
    this.articleCardTarget.removeAttribute("data-controller");
    this.stopAllTimers();
  }

  // showAudioPlayer() {
  //   document.querySelector()
  // }

  stopAllTimers() {
    if (this.readStartTimer) clearInterval(this.readStartTimer);
    if (this.activityTimer) clearInterval(this.activityTimer);
  }

  scrollToNote() {
    var note_hash = window.location.hash.substr(1);
    if (note_hash == undefined || note_hash == "") return;
    var element = document.querySelector("#" + note_hash);
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  scrollToComment() {
    if (window.location.href.indexOf("?show_comment=") != -1) {
      var comment_id = window.location.href.split("?").pop().split("=").pop();
      var comment = document.querySelector(`#comment_${comment_id}`);
      if (!!comment) comment.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  highlightFeedbackSelectedText() {
    var selectedText = this.articleCardTarget.dataset.feedbackSelectedText;
    window.find(selectedText, false);
  }

  highlightQuestionSelectedText() {
    var selectedText = this.articleCardTarget.dataset.questionSelectedText;
    window.find(selectedText, false);
  }

  startActivityTimer(seconds, oncomplete) {
    var startTime,
      timer,
      obj,
      ms = seconds * 1000;
    obj = {};
    obj.resume = function () {
      startTime = new Date().getTime();
      timer = setInterval(obj.step, 250);
      // console.log("action: resume", timer)
    };
    obj.pause = function () {
      ms = obj.step();
      // console.log("action: pause", timer)
      clearInterval(timer);
    };
    obj.step = function () {
      var now = Math.max(0, ms - (new Date().getTime() - startTime)),
        m = Math.floor(now / 60000),
        s = Math.floor(now / 1000) % 60;
      s = (s < 10 ? "0" : "") + s;
      if (now == 0) {
        clearInterval(timer);
        obj.resume = function () {};
        if (oncomplete) oncomplete();
      }
      return now;
    };
    obj.resume();
    return obj;
  }

  isInViewport(element, exactPosition=true) {
    if (element == null) return;
    const rect = element.getBoundingClientRect();
    let bottom = exactPosition ? rect.bottom : rect.bottom - 410;

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  startActivityTracker(currentArticle) {
    
    this.spentMinTime = false;
    this.scrollReachedEnd = false;
    this.currentReadCount = this.articleCardTarget.dataset.readCount;
    this.readStartNotified = false;
    this.addedQuickComment = false;

    if (this.players && this.players.length > 0) {
      
      const firstPlayer = this.players[0];
      
      firstPlayer.on('play', event => {
   
        if (this.firstPlayerStartedAt == undefined) {
          this.firstPlayerDurationInMinutes = Math.floor(firstPlayer.media.duration / 60)
          this.firstPlayerStartedAt = new Date();
        }
      })
      firstPlayer.on('ended', event => {
        const instance = event.detail.plyr;
  
        var minutesElapsed = Math.round((new Date().getTime() - this.firstPlayerStartedAt.getTime()) / 60000)

      
        if (minutesElapsed >= this.firstPlayerDurationInMinutes) {
            // if (this.currentReadCount == 0) {
            //   this.track(`/articles/${this.articleId}/activity/track`);
            // } else {
              this.completedAudio = true;
            // }
        }

      })
    }

    this.readStartTimer = this.startActivityTimer(60, () => {
      if (!this.hasArticleCardTarget) return;
      if (this.readStartNotified) return;
      if (
        document.querySelector("#article-card") == null ||
        currentArticle != document.querySelector("#article-card")
      )
        return;
      this.track(`/articles/${this.articleId}/activity/track/read-started`);
      this.readStartNotified = true;
    });

    var readTime = 900;

    if (!this.fromBook) {


      if (this.articleCategory == "book_mangiare_sano") {
        readTime = 180;
      } else {
        if (this.articleNumber == 0 || this.articleNumber == 50 || this.articleNumber == 100 || this.articleId == 28875) {
          readTime = 80;
        } else {
          var pages = [...document.querySelectorAll("#article-card span > i > strong > u")].filter(el => el.innerText.match(/Fine\spagina\s\d\d?\d?\s(su|di)\s\d\d?\d?/gmi) )
          if (pages.length > 0) {
            var pagesNumber = parseInt(pages[0].innerText.match(/\d\d?\d?/mig)[1])
            if (!!pagesNumber && pagesNumber <= 4) {
              readTime = 300
            }
          }
        }
      }
     
    

    
    } else {
      // from book
      readTime = 420 // 7 minutes
      if ([516286, 516148, 516199].includes(this.articleId)) {
        readTime = 80
      }
    
    }

    // console.log("READ_TIME", readTime)

    this.activityTimer = this.startActivityTimer(readTime, () => {
      if (!this.hasArticleCardTarget) return;
      if (this.spentMinTime) return;
      if (!this.readStartNotified) return;
      if (
        document.querySelector("#article-card") == null ||
        currentArticle != document.querySelector("#article-card")
      )
        return;
      // after minimum time (readTime)
      this.spentMinTime = true;
      document.addEventListener("scroll", (event) => {
        if (this.isInViewport(document.querySelector("#copyright_notice"), false)) {
          if (this.spentMinTime && !this.scrollReachedEnd) {
            this.scrollReachedEnd = true;

            // already added a comment?
            if (this.addedQuickComment) {
              this.track(`/articles/${this.articleId}/activity/track`);
            }

          }
        }
      });
    });
  }

  notifyNewCommentFromStream() {
    this.addedQuickComment = true;

    if ( this.bypassReadCheck && this.scrollReachedEnd || ( this.currentReadCount && ((this.spentMinTime && this.scrollReachedEnd) || this.completedAudio) ) ) {
      this.track(`/articles/${this.articleId}/activity/track`);
    }

  }

  track(url) {
    fetch(url, {
      method: "post",
      credentials: "same-origin",
      headers: {
        'Accept': "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
    }).then((response) => {
      if (response.ok) {
        this.readCompletedNotified = true
      }
    });
  }

  logActivity() {}

  away(event) {
    if (this.spentMinTime == false) {
      // console.log("read paused")
      if (this.readStartTimer) this.readStartTimer.pause();
      if (this.activityTimer) this.activityTimer.pause();
    }

    // if (document.querySelector("#article-card") == null || !this.hasArticleCardTarget) {
   
    //   clearInterval(this.readStartTimer)
    //   clearInterval(this.activityTimer)
    // }
  }

  back(event) {
    if (this.spentMinTime == false) {
      // console.log("read resumed")
      if (this.readStartTimer) this.readStartTimer.resume();
      if (this.activityTimer) this.activityTimer.resume();
    }
  }

  setKeyboardNavigation() {
    // TODO check inputs focus and active modals
    document.addEventListener('keydown', function(e) {
      if (e.keyCode == 37) {
        Turbo.visit(document.querySelector('#goToPrevArticle').href)
      } else if (e.keyCode == 39) {
        Turbo.visit(document.querySelector('#goToNextArticle').href)
      }
    })
  }

}
