// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { Autocomplete } from "stimulus-autocomplete"
import ScrollTo from "stimulus-scroll-to"
import TextareaAutogrow from "stimulus-textarea-autogrow"

const application = Application.start()
application.register('autocomplete', Autocomplete)
application.register("scroll-to", ScrollTo)
application.register("textarea-autogrow", TextareaAutogrow)

const context = require.context("controllers", true, /_controller\.(js|ts)$/)
application.load(definitionsFromContext(context))
